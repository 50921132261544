import React from "react";
import Member from "../models/Member";
import { observable } from "mobx";
import { Permission, can } from "./RequirePermission";
import { safeNull } from "../common/Util";
import { observer } from "mobx-react";
import MemberTestimonyForm from "./MemberTestimonyForm";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Testimony from "../models/Testimony";


type Props = {
//   testimony: Testimony
  member: Member
  onMemberInvalidated: () => void
}
@observer
export default class MemberTestimony extends React.Component<Props, never> {
  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'lg'
  @observable
  private editModalTitle = 'Edit'

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'lg') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  render (): React.ReactNode {
      const { member } = this.props
      const testimony = member.testimony[0] == undefined ?  member.testimony : member.testimony[0];
      return member?
        <>
          <div className="detail-pane">
            <h5 className="text-muted">My Success Story {
              can(Permission.EditMember, { memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) })
              ? <a href="" onClick={ev => {
                  ev.preventDefault()
                  this.showEditModal('My Success Story',
                  <MemberTestimonyForm
                    member={member}
                    onCancel={() => this.closeEditModal()}
                    onSaved={() => {
                      this.closeEditModal()
                      this.props.onMemberInvalidated()
                    }}
                  />)
              }}><i className="fa fa-pencil"/></a>
              : null
         } </h5>
        {
                testimony.testimony
                  // ? <div className="display-linebreaks">{safeNull(() =>testimony!.testimony)|| '---'}</div>
                ? <div className="display-linebreaks">{testimony.testimony}</div>
                  : <div className="text-muted">You have not entered a Success Story yet</div>
              }
          </div>
          <Modal
          isOpen={this.showModal}
          size={this.editModalSize}
        >
          <ModalHeader>{this.editModalTitle}</ModalHeader>
          <ModalBody>{this.editModalContent}</ModalBody>
        </Modal>
        </>:
      <p>Testimony not found</p>

    }
}