import { observer } from 'mobx-react'
import React from 'react'
import { Button } from 'reactstrap'
import { observable } from 'mobx'
import Member from '../../models/Member'
import ApiClient from '../../api/ApiClient'
import { ScheduleBoardTrainingModal } from './ScheduleBoardTrainingModal'

type Props = {
  memberId: number
}

@observer
export class ScheduleBoardTrainingButton extends React.Component<Props> {
  @observable private renderScheduleBoardTrainingEventModal = false
  @observable private member?: Member

  loadMember = async () => {
    let response = await ApiClient.query(`
    member {
      boardTrainingCompletedAt
    }`, {
      where: [{ id: this.props.memberId }],
    })

    this.member = new Member(response.data.member)
  }

  componentDidMount () {
    this.loadMember().then()
  }

  render () {
    return <>
      {
        this.member
          ? <Button color="primary" block onClick={() => this.renderScheduleBoardTrainingEventModal = true}>
            {this.member.boardTrainingCompletedAt ? 'View Available Board Training Events' : 'Schedule Board Training'}
          </Button>
          : null
      }
      {
        this.renderScheduleBoardTrainingEventModal
          ? <ScheduleBoardTrainingModal onClosed={() => this.renderScheduleBoardTrainingEventModal = false} memberId={this.props.memberId}/>
          : null
      }
    </>
  }
}
