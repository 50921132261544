import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { observable } from "mobx"
import Util, { publicPath } from "../../common/Util"
import Config from "../../common/Config"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import { route } from "../../routes/routes"
import Event from '../../models/Event'
import { Link } from "react-router-dom"
import { Routes } from "../../routes/AppRoutes"
import AddRenewalPaymentMethodForm from "../../components/AddRenewalPaymentMethodForm"
import ButtonLoader from "../../components/ButtonLoader"
import AppStateStore from "../../stores/AppStateStore"

const uuid4 = require('uuid/v4')

type Props = {
  match: {
    params: {
      slug: string
    }
  },
}

@observer
export default class LegacyMemberOnboardingView extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private event?: Event
  @observable private submitting = false
  @observable private showThankYou = false
  @observable private onboarded = false
  @observable private fullName?: string
  @observable private enableAutoRenew = false
  @observable private paymentMethodData?: {
    dataDescriptor: string
    dataValue: string
    description: string
  }

  componentDidMount (): void {
    this.loading = true

    ApiClient.getInstance()
      .get(route(ApiRoutes.legacy.onboarding.getData, { slug: this.props.match.params.slug }))
      .then(response => {
        this.loading = false
        this.onboarded = response.data.onboarded
        this.fullName = response.data.full_name
      })
      .catch(err => this.error = err.response.status === 404 ? 'Sorry, the page you are looking for was not found!' : Util.extractErrorMessage(err.response))
      .then(() => this.loading = false)
  }

  private submit = (ev: SyntheticEvent) => {
    this.submitting = true
    AppStateStore.showModalSpinner()

    ev.preventDefault()

    ApiClient.getInstance()
      .post(route(ApiRoutes.legacy.onboarding.addPaymentMethod, { slug: this.props.match.params.slug }), {
        data_descriptor: this.paymentMethodData!.dataDescriptor,
        data_value: this.paymentMethodData!.dataValue,
        enable_auto_renew: this.enableAutoRenew,
      })
      .then(() => {
        this.showThankYou = true
      })
      .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
      .then(() => {
        this.submitting = false
        AppStateStore.dismissModalSpinner()
      })
  }

  private renderThankYou = () => {
    return <div className="text-center">
      Thank you for completing the setup of your payment profile!
      <br/><br/>
      <Link to={route(Routes.login)}>Click here to log in</Link>
    </div>
  }

  private renderOnboarding = () => {
    return this.onboarded
      ? <div className="alert alert-info">
        It looks like you have already completed your account setup.
        <br/><br/>
        <Link to={route(Routes.login)}>Click here to log in</Link>
      </div>
      :
      <div className="card">
        <div className="card-header">
          {this.fullName ? <h5>Welcome back, {this.fullName}!</h5> : null}
        </div>
        <div className="card-body">
          <p>
            In order to finish setting up your account in the new Wired, please add a payment method.
          </p>
          <p>
            You can also choose whether or not you'd like enable automatic renewals.
          </p>
          {this.paymentMethodData
            ? <>
              <div className="row">
                <div className="col-md-6 col-12">
                  <p>
                    {this.paymentMethodData.description}
                    &nbsp;
                    <a href="" onClick={ev => {
                      ev.preventDefault()
                      this.paymentMethodData = undefined
                    }}>Change</a>
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <div className="form-group">
                    <div className="form-check form-checkbox">
                      <label>
                        <input type="checkbox"
                               className="form-check-input"
                               name="remember"
                               checked={this.enableAutoRenew}
                               onChange={ev => this.enableAutoRenew = ev.target.checked}
                        />
                        <span className="label-text">Enable auto renewal</span>
                      </label>
                    </div>
                  </div>
                  {
                    this.enableAutoRenew
                      ? <p>
                        I agree to have my LeTip membership automatically renew at the current rate.
                        My membership will renew annually on the month that my membership application is approved by LeTip International, Inc.
                        I acknowledge that LeTip International, Inc. reserves the right to increase membership dues; I understand that I will be given not less than 30 day notice of any such increase.
                        <b>If I cancel the automatic renewal option for my membership, I will enter my request into LeTip Wired&trade; or I must send a written notice, not less than 30 days, before cancellation to: LeTip International, Inc., 7895 West Sunset Road, Suite 101, Las Vegas, NV 89113.</b>
                      </p>
                      : null
                  }
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-12">
                  <ButtonLoader
                    color="primary"
                    onClick={this.submit}
                    loading={this.submitting}>
                    Continue
                  </ButtonLoader>
                </div>
              </div>
            </>
            : <>
              <div className="row">
                <div className="col-md-6 col-12">
                  <AddRenewalPaymentMethodForm
                    onPaymentMethodAdded={(dataDescriptor: string, dataValue: string, description: string) => {
                      this.paymentMethodData = {
                        dataDescriptor: dataDescriptor,
                        dataValue: dataValue,
                        description: description,
                      }
                    }}
                    onStoredPaymentMethodAdded={(uuid: string, description: string, paymentMethodType: string) => {
                      this.paymentMethodData = {
                        dataDescriptor: 'tokenized_uuid',
                        dataValue: uuid,
                        description: description,
                      }
                    }}
                  />
                </div>
              </div>
            </>
          }
        </div>
      </div>
  }

  render (): React.ReactNode {
    return <>
      <div className="header-container">
        <div className="brand">
          <img className="brand-image" alt="LeTip Wired" src={publicPath('img/wired-logo.png')}/>
        </div>
        <div className="header-nav"/>
      </div>
      <div className="body-container invitation-response">
        <div className="left-nav"/>
        <div className="content-container">
          <div className="content">
            {this.error
              ? <div className="alert alert-danger">{this.error}</div>
              : this.loading
                ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
                : this.showThankYou
                  ? this.renderThankYou()
                  : this.renderOnboarding()
            }
          </div>
          <div className="content-footer">
            Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
          </div>
        </div>
      </div>
    </>
  }
}
