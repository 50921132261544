import Member from "../models/Member"
import * as React from "react"
import { createLazyResource, makeUrl, safeNull } from "../common/Util"
import { computed, observable } from "mobx"
import ApiClient from "../api/ApiClient"
import { observer } from "mobx-react"
import LazyResourcePanel from "./LazyResourcePanel"
import { Link } from "react-router-dom"
import { route } from "../routes/routes"
import { Routes } from "../routes/AppRoutes"
import SponsorList from "./SponsorList"
import SponsoredList from "./SponsoredList"
import RequirePermission, { Permission } from "./RequirePermission"
import BadgeShippingRequest from "../models/BadgeShippingRequest"
import TableView, { TableViewColumn } from "./table-view/TableView"
import { ArrayTableViewAdapter } from "./table-view/ArrayTableViewAdapter"
import EventBus, { EventBusContext } from "../common/EventBus"
import * as _ from "lodash"
import RequestResignationModal from "./RequestResignationModal"
import AuthStore from "../stores/AuthStore"
import AddressView from './AddressView'
import RequestCategoryChangeModal from './member/RequestCategoryChangeModal'
import { Alert, Button } from 'reactstrap'
import { LinkContainer } from 'react-router-bootstrap'
import RequestCompanyReplacementModal from './member/RequestCompanyReplacementModal'
import { ScheduleNtsTrainingModal } from './member/ScheduleNtsTrainingModal'
import { ScheduleNtsTrainingButton } from './member/ScheduleNtsTrainingButton'
import { ScheduleBoardTrainingButton } from "./member/ScheduleBoardTrainingButton"

@observer
export default class MemberNetworkInfo extends React.Component<{
  member: Member
}> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  componentDidMount (): void {
    this.addListeners(this.context.eventBus)
  }

  componentWillUnmount (): void {
    this.removeListeners(this.context.eventBus)
  }

  private addListeners = (eventBus: EventBus) => {
    eventBus.on('member-invalidated', this.onMemberInvalidated)
  }

  private removeListeners = (eventBus: EventBus) => {
    eventBus.remove(this.onMemberInvalidated)
  }

  @observable private member = createLazyResource<Member>(() => {
    return ApiClient.query(
      `
member {
  *
  chapter {
    *
    area {
      *
    }
  }
  category {
    *
  }
  networkAuditLogs {
    *
  }

  applications {
    applicationPdfUrl
    status
  }

  categoryChangeRequests {
    submittedAt
    applicationPdfUrl
    isProcessed
  }

  chapterTransferRequests {
    submittedAt
    applicationPdfUrl
    isProcessed
  }

  companyReplacementRequests {
    isInQueue
  }
}
      `,
      {
        where: [{ id: this.props.member.id }]
      }
    )
  }, response => new Member(response.data.member))

  private shippingHistory = createLazyResource<BadgeShippingRequest[]>(() => {
    return ApiClient.query(
      `
    badgeShippingRequests {
      *

      badgeShippingAddress {
        *
      }
    }
    `,
      {
        where: [
          { memberId: this.props.member.id },
          { status: 'Shipped' },
        ]
      }
    )
  }, response => response.data.badgeShippingRequests.map((r: {}) => new BadgeShippingRequest().init(r)))

  private shippingHistoryColumns: TableViewColumn[] = [
    {
      title: 'Shipped',
      accessor: 'completedAt',
      renderItem: value => value.format('MM/DD/YYYY'),
    },
    {
      title: 'Badge Color',
      accessor: 'badgeColor',
    },
    {
      title: 'Notes',
      accessor: 'shippingNotes',
    },
    {
      title: 'Address To',
      accessor: 'badgeShippingAddress.name',
    },
    {
      title: 'Address',
      accessor: 'badgeShippingAddress',
      renderItem: value => value
        ? <AddressView address={value} showMapLink={true}/>
        : null
    },
    {
      title: 'Notes',
      accessor: 'shippingNotes',
    },
  ]

  private onMemberInvalidated = () => {
    this.member.invalidate()
  }

  private invalidateMember = () => {
    this.context.eventBus.dispatch('member-invalidated')
  }

  @computed
  private get membershipApplication () {
    return this.member.current && this.member.current.applications.find(a => a.status === 'Approved')
  }

  @computed
  private get applicationPdfUrl () {
    return (this.membershipApplication && this.membershipApplication.applicationPdfUrl)
      ? this.membershipApplication.applicationPdfUrl
      : null
  }

  @observable private showResignationModal = false
  @observable private renderResignationModal = false
  @observable private renderCategoryChangeModal = false
  @observable private renderCompanyReplacementModal = false

  render (): React.ReactNode {
    return <>
      <div className="row light-border-bottom">
        <div className="col-md-4 light-border-right pb-4">
          <h6 className="text-muted mb-4">Chapter and Category Information</h6>
          <LazyResourcePanel resource={this.member}>
            {
              member => <>
                <div className="mb-2">
                  <b>Chapter Name</b>
                  <div>{member.chapter ? <Link to={route(Routes.system.chapters.show, { id: member.chapter!.id })}>{member.chapter!.name}</Link> : '---'}</div>
                </div>
                <div className="mb-2">
                  <b>Category</b>
                  <div>{safeNull(() => member.category!.name) || '---'}</div>
                </div>
                <div className="mb-2">
                  <b>Area</b>
                  <div>{safeNull(() => member.chapter!.area!.name) || '---'}</div>
                </div>
                <RequirePermission permission={Permission.PerformMemberActions} context={{ memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) }}>
                  {() => <>
                    <div>
                      {
                        member.chapterTransferRequests.filter(c => !c.isProcessed).length
                          ? <Alert color="info" className="text-center">
                            Chapter Transfer Pending
                          </Alert>
                          : <LinkContainer to={route(Routes.member.requestChapterTransfer, { memberId: member.id })}>
                            <Button
                              type="button"
                              color="primary"
                              block
                              onClick={() => {
                              }}
                            >Request Chapter Transfer</Button>
                          </LinkContainer>
                      }
                    </div>
                    <div className="mt-2">
                      {
                        member.categoryChangeRequests.filter(c => !c.isProcessed).length
                          ? <Alert color="info" className="text-center">
                            Category Change Pending
                          </Alert>
                          : <Button
                            type="button"
                            color="primary"
                            block
                            onClick={() => this.renderCategoryChangeModal = true}
                          >Request A Category Change</Button>
                      }
                    </div>
                    <div className="mt-2">
                      {
                        this.applicationPdfUrl
                          ? <a
                            className="btn btn-primary btn-block"
                            href={makeUrl(this.applicationPdfUrl, { token: AuthStore.generateTempAuthToken() })}
                            target="_blank"
                          >Download Application</a>
                          : null
                      }
                    </div>
                  </>}
                </RequirePermission>
              </>
            }
          </LazyResourcePanel>
        </div>
        <div className="col-md-4 light-border-right">
          <h6 className="text-muted mb-4">Network Status</h6>
          <LazyResourcePanel resource={this.member}>
            {
              member => <>
                <div className="stacked-labeled-list label-200" style={{ minHeight: 140 }}>
                  <div>
                    <label>Membership:</label>
                    <span>{member.status}</span>
                  </div>
                  <div>
                    <label>Join Date:</label>
                    <span>{safeNull(() => member.joinDate!.format('MM/DD/YYYY')) || '---'}</span>
                  </div>
                  <div>
                    <label>Type:</label>
                    <span>{member.membershipType || '---'}</span>
                  </div>
                  <div>
                    <label>Badge Color:</label>
                    <span>{member.badgeColor || '---'}</span>
                  </div>
                </div>
                <RequirePermission permission={Permission.PerformMemberActions} context={{ memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) }}>
                  {() => <>
                    <div>
                      <a
                        className="btn btn-primary btn-block"
                        href=""
                        onClick={ev => {
                          ev.preventDefault()

                          this.showResignationModal = true
                          this.renderResignationModal = true
                        }}
                      >Request Resignation</a>
                    </div>
                    {
                      member.membershipType === 'Company'
                        ? <div className="mt-2">
                          {
                            member.companyReplacementRequests.filter(c => c.isInQueue).length
                              ? <Alert color="info" className="text-center">
                                Company Replacement Pending
                              </Alert>
                              : <Button
                                type="button"
                                color="primary"
                                block
                                onClick={() => this.renderCompanyReplacementModal = true}
                              >Company Membership Replacement</Button>
                          }
                        </div>
                        : null
                    }
                  </>}
                </RequirePermission>
              </>
            }
          </LazyResourcePanel>
        </div>
        <div className="col-md-4">
          <h6 className="text-muted mb-4">Training</h6>
          <LazyResourcePanel resource={this.member}>
            {
              member => <>
                <div className="stacked-labeled-list label-200" style={{ minHeight: 140 }}>
                  <div>
                    <label>NTS Completed:</label>
                    <span>{safeNull(() => member.ntsTrainingCompletedAt!.format('MM/DD/YYYY')) || '---'}</span>
                  </div>
                  <div>
                    <label>Board Meeting Completed:</label>
                    <span>{safeNull(() => member.boardTrainingCompletedAt!.format('MM/DD/YYYY')) || '---'}</span>
                  </div>
                </div>

                <div>
                  <RequirePermission permission={Permission.PerformMemberActions} context={{ memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) }}>
                    {() => <>
                      <ScheduleNtsTrainingButton memberId={member.id}/>
                    </>}
                  </RequirePermission>
                  <RequirePermission permission={Permission.PerformMemberActions} context={{ memberId: member.id, chapterId: member.chapterId, regionId: safeNull(() => member.chapter!.area!.region.id) }}>
                    {() => <>
                      <ScheduleBoardTrainingButton memberId={member.id}/>
                    </>}
                  </RequirePermission>
                </div>
              </>
            }
          </LazyResourcePanel>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h4>Sponsored By</h4>
          <LazyResourcePanel resource={this.member}>
            {member => <SponsorList memberId={member.id} onMemberInvalidated={this.invalidateMember}/>}
          </LazyResourcePanel>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h4>Sponsored {this.member.current ? `(${this.member.current.numberOfSponsoredMembers})` : null}</h4>
          <LazyResourcePanel resource={this.member}>
            {member => <SponsoredList memberId={member.id} onMemberInvalidated={this.invalidateMember}/>}
          </LazyResourcePanel>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <h4>Network Activity</h4>
          <LazyResourcePanel resource={this.member}>
            {member => <>
              {
                member.networkAuditLogs.length
                  ? _.orderBy(member.networkAuditLogs, ['createdAt'], ['desc']).map(log => <div key={log.id}>
                    <b>{log.createdAt.format('MM/DD/YYYY')}</b><br/>
                    {log.description}
                  </div>)
                  : 'There is no activity for this member'
              }
            </>}
          </LazyResourcePanel>
        </div>
      </div>
      <RequirePermission permission={Permission.ViewMemberShippingHistory}>
        {() =>
          <LazyResourcePanel resource={this.shippingHistory}>
            {badgeShippingRequests => <div className="row mt-4">
              <div className="col-12">
                <h4>Shipping History</h4>
                <TableView
                  hideItemCount={true}
                  hideRowNumbers={true}
                  adapter={new ArrayTableViewAdapter(badgeShippingRequests)}
                  columns={this.shippingHistoryColumns}
                />
              </div>
            </div>}
          </LazyResourcePanel>
        }
      </RequirePermission>

      {
        this.renderResignationModal
          ? <RequestResignationModal
            memberId={AuthStore.getUser()!.member!.id}
            isOpen={this.showResignationModal}
            toggle={() => this.showResignationModal = false}
            onClosed={() => this.renderResignationModal = false}
          />
          : null
      }

      {
        (this.renderCategoryChangeModal && this.member.current)
          ? <RequestCategoryChangeModal
            chapterId={this.member.current.chapterId}
            memberId={this.member.current.id}
            onClosed={() => {
              this.renderCategoryChangeModal = false
              this.invalidateMember()
            }}
          />
          : null
      }

      {
        (this.renderCompanyReplacementModal && this.member.current)
          ? <RequestCompanyReplacementModal
            chapterId={this.member.current.chapterId}
            memberId={this.member.current.id}
            onClosed={() => {
              this.renderCompanyReplacementModal = false
              this.invalidateMember()
            }}
          />
          : null
      }
    </>
  }
}
