import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import FormState from "../../../common/FormState"
import { observable } from "mobx"
import ErrorBag from "../../../common/ErrorBag"
import FormHelper from "../../../forms/FormHelper"
import { loadChapterOptions, loadChapterOptionsForAdmin } from "../../../api/AsyncHelpers"
import moment, { Moment } from 'moment-timezone'
import { Button } from "reactstrap"
import { BarLoader } from "react-spinners"
import ApiClient, { ApiRoutes } from "../../../api/ApiClient"
import { route } from "../../../routes/routes"
import Util, { formatCurrency, formatNumber, modelToCamelCase, modelToSnakeCase } from "../../../common/Util"
import * as _ from "lodash"
import ExportButton, { ExportButtonFetchCallback } from "../../ExportButton"
import ChapterTipMasterOverviewCsvTransformer from "../../../models/renderers/ChapterTipMasterOverviewCsvTransformer"
import { relative } from "path"

export type TipMasterOverviewReportData = {
  member: {
    id: number
    firstName: string
    lastName: string
    status: string
  }

  tipData: {
    inside: {
      sent: number
      sentValue: number
      received: number
      receivedValue: number
    },
    outside: {
      sent: number
      sentValue: number
      received: number
      receivedValue: number
    },
    socialMedia: {
      sent: number
      sentValue: number
      received: number
      receivedValue: number
    },
    interChapter: {
      sent: number
      sentValue: number
      received: number
      receivedValue: number
    },
    batches: {
      count: number,
      receivedValue: number,
    }
  }
}

type Props = {
  chapterId?: number
  filterForAdmin?: boolean
}

@observer
export default class ChapterTipMasterOverviewReport extends React.Component<Props> {
  @observable private loading = false
  @observable private error?: string
  @observable private formState = new FormState({
    chapterId: undefined,
    startDate: moment().startOf('month').format('MM/DD/YYYY'),
    endDate: moment().endOf('month').format('MM/DD/YYYY'),
  })

  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable chapterData?: { id: number, name: string }
  @observable reportData?: TipMasterOverviewReportData[]
  @observable startDate?: Moment
  @observable endDate?: Moment

  componentDidMount (): void {
    if (this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.chapterId != this.props.chapterId) {
      this.formState.set('chapterId', this.props.chapterId)
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.error = undefined
    this.loading = true

    ApiClient.getInstance()
      .post(route(ApiRoutes.reporting.chapterTipMasterOverviewReport, { chapterId: this.formState.get('chapterId') }), modelToSnakeCase(this.formState.toObject()))
      .then(response => {
        this.chapterData = modelToCamelCase(response.data.chapter) as any
        this.reportData = response.data.report_data.map((rd: any) => modelToCamelCase(rd))
        this.startDate = moment(response.data.start_date);
        this.endDate = moment(response.data.end_date)
      })
      .catch(error => this.error = Util.extractErrorMessage(error.response))
      .then(() => this.loading = false)
  }

  render () {
    return <>
      <div className="print-hide">
        <form action="" method="post" onSubmit={this.submit}>
          <div className="row align-items-end">
            {
              !this.props.chapterId
                ? <div className="col-md-4">
                  {this.formHelper.renderAsyncSelectInput({
                    label: 'Chapter',
                    name: 'chapterId',
                    loadOptions: this.props.filterForAdmin ? loadChapterOptionsForAdmin : loadChapterOptions,
                  })}
                </div>
                : null
            }
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'Start Date',
                name: 'startDate',
              })}
            </div>
            <div className="col-md-3">
              {this.formHelper.renderDatePickerInput({
                label: 'End Date',
                name: 'endDate',
              })}
            </div>
            <div className="col-md-2 d-flex">
              <div className="form-group d-flex flex-column">
                <div className="flex-fill"/>
                <div>
                  <Button
                    disabled={this.loading || !this.formState.get('chapterId') || !this.formState.get('startDate').length || !this.formState.get('endDate').length}
                    style={{
                      height: 'calc(2.95rem + 2px)'
                    }}
                    type="submit"
                    color="success"
                  ><i className="fa fa-play"/> Run Report</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      {
        this.loading
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.error
          ? <div className="alert alert-danger">{this.error}</div>
          : this.reportData
            ? this.renderReport()
            : <p>Choose report parameters above</p>
      }
    </>
  }

  private fetchPrintData: ExportButtonFetchCallback<TipMasterOverviewReportData> = (onFetched, onError, onFinished) => {
    if (this.reportData) {
      onFetched(this.reportData)
    }

    onFinished()
  }

  private renderReport () {
    const chapter = this.chapterData!
    const reportData = this.reportData!
    const startDate = this.startDate!
    const endDate = this.endDate!

    return <>
      <h1 className="text-center my-4">Tip Master Overview Report For {chapter.name}</h1>
      <h3 className="text-center mb-4">{startDate.format('MMMM D, YYYY')} - {endDate.format('MMMM D, YYYY')}</h3>
      <div className="form-buttons mb-4 print-hide">
        <ExportButton
          fetch={this.fetchPrintData}
          transformerClass={() => new ChapterTipMasterOverviewCsvTransformer()}
        />
        <Button
          type="button"
          color="primary"
          onClick={() => window.print()}
        ><i className="fa fa-print"/> Print Report</Button>
      </div>
      <div className="row" style={{display: "flex", width:"100%"}}>
        <h5 style={{marginLeft:"35%"}}>
          <b>Sent Tips</b>
        </h5>
        <h5 style={{marginLeft:"35%"}}>
          <b>Received Tips</b>
        </h5>
      </div>
      <table className="chapter-tip-report-table">
        <thead>
        <tr>
          <th/>
          <th className="border-left border-top border-bottom">First Name</th>
          <th className="border-top border-bottom">Last Name</th>
          <th className="border-left border-top border-bottom">Inside</th>
          <th className="border-top border-bottom text-right">Outside</th>
          <th className="border-top border-bottom text-right">Social Media</th>
          <th className="border-top border-bottom">Inter Chapter</th>
          <th className="border-top border-bottom">Total</th>
          <th className="border-top border-bottom text-right">Total $</th>
          <th className="border-left border-top border-bottom">Inside</th>
          <th className="border-top border-bottom text-right">Outside</th>
          <th className="border-top border-bottom text-right">Social Media</th>
          <th className="border-top border-bottom">Inter Chapter</th>
          <th className="border-top border-bottom">Total</th>
          <th className="border-top border-bottom text-right">Total $</th>
        </tr>
        </thead>
        <tbody>
        {
          reportData.map((memberTipData, index) => <tr key={memberTipData.member.id}>
            <td className="text-right">{index + 1}</td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {memberTipData.member.firstName}
            </td>
            <td style={{ whiteSpace: 'nowrap' }}>
              {memberTipData.member.lastName}
            </td>
            <td className="border-left text-center">{formatNumber(memberTipData.tipData.inside.sent)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.outside.sent)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.socialMedia.sent)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.interChapter.sent)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.inside.sent + memberTipData.tipData.outside.sent + memberTipData.tipData.socialMedia.sent + memberTipData.tipData.interChapter.sent)}</td>
            <td className="text-right">{formatCurrency(memberTipData.tipData.inside.sentValue + memberTipData.tipData.outside.sentValue + memberTipData.tipData.socialMedia.sentValue + memberTipData.tipData.interChapter.sentValue)}</td>
            <td className="border-left text-center">{formatNumber(memberTipData.tipData.inside.received)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.outside.received)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.socialMedia.received)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.interChapter.received)}</td>
            <td className="text-center">{formatNumber(memberTipData.tipData.inside.received + memberTipData.tipData.outside.received + memberTipData.tipData.socialMedia.received + memberTipData.tipData.interChapter.received)}</td>
            <td className="text-right">{formatCurrency(memberTipData.tipData.inside.receivedValue + memberTipData.tipData.outside.receivedValue + memberTipData.tipData.socialMedia.receivedValue + memberTipData.tipData.interChapter.receivedValue + memberTipData.tipData.batches.receivedValue)}</td>
          </tr>)
        }
        <tr className="border-top border-bottom">
          <td/>
          <td colSpan={2}>Total</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.inside.sent))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.outside.sent))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.socialMedia.sent))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.interChapter.sent))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.inside.sent + m.tipData.outside.sent + m.tipData.socialMedia.sent + m.tipData.interChapter.sent))}</td>
          <td className="text-right">{formatCurrency(_.sumBy(reportData, m => m.tipData.inside.sentValue + m.tipData.outside.sentValue + m.tipData.socialMedia.sentValue + m.tipData.interChapter.sentValue))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.inside.received))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.outside.received))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.socialMedia.received))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.interChapter.received))}</td>
          <td className="text-center">{formatNumber(_.sumBy(reportData, m => m.tipData.inside.received + m.tipData.outside.received + m.tipData.socialMedia.received + m.tipData.interChapter.received))}</td>
          <td className="text-right">{formatCurrency(_.sumBy(reportData, m => m.tipData.inside.receivedValue + m.tipData.outside.receivedValue + m.tipData.socialMedia.receivedValue + m.tipData.interChapter.receivedValue + m.tipData.batches.receivedValue))}</td>
        </tr>
        </tbody>
      </table>
    </>
  }
}
