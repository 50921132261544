import * as React from "react"
import { observer } from "mobx-react"
import Tip from "../models/Tip"
import MemberCell from "./cells/MemberCell"
import Util, { formatNumber, modelToCamelCase, modelToSnakeCase, nl2br, safeNull } from "../common/Util"
import RequirePermission, { can, Permission } from "./RequirePermission"
import { computed, observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { Button } from "reactstrap"
import { submitFormRequest } from "../api/ApiHelper"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { route } from "../routes/routes"
import { toast } from "react-toastify"
import AppStateStore from "../stores/AppStateStore"
import ButtonLoader from "./ButtonLoader"
import classNames from 'classnames'
import { loadMemberOptions } from "../api/AsyncHelpers"
import Member from "../models/Member"
import { EditTipModal } from "./EditTipModal"
import HelpTooltip from './HelpTooltip'
import { TipFormModal } from './TipFormModal'
import AuthStore from '../stores/AuthStore'

type Props = {
  tip: Tip
  showCheckbox?: boolean
  isChecked?: boolean
  onChecked?: (checked: boolean) => void
  className?: string
  onInvalidated?: () => void
}

@observer
export default class TipCard extends React.Component<Props> {
  @observable private isAssigning = false
  @observable private isAssigningFrom = false
  @observable private renderDuplicateTipModal = false
  @observable private showEditModal = false
  @observable private renderEditModal = false
  @observable private submitting = false

  private checkboxRef = React.createRef<HTMLInputElement>()

  @observable private assignFormState = new FormState({
    memberId: undefined,
  })

  @observable private assignFormErrors = new ErrorBag()

  private assignFormHelper = new FormHelper(this.assignFormState, this.assignFormErrors)

  @observable private assignFromFormState = new FormState({
    memberId: undefined,
  })

  @observable private assignFromFormErrors = new ErrorBag()

  private assignFromFormHelper = new FormHelper(this.assignFromFormState, this.assignFromFormErrors)

  private openInNewTab = (url:any) => {
    window.open(url, '_blank', 'noreferrer');
  };

  private assignTip = () => {
    AppStateStore.showModalSpinner()
    this.submitting = true

    submitFormRequest(
      ApiClient.getInstance()
        .post(route(ApiRoutes.tips.assign, { id: this.props.tip.id }), modelToSnakeCase(this.assignFormState.toObject())),
      this.assignFormState,
      this.assignFormErrors,
      response => {
        toast.success('Tip Assigned')

        this.props.tip.targetMember = new Member().init(modelToCamelCase(response.data.tip.target_member))

        this.assignFormState.setAll({
          memberId: undefined,
        })

        this.isAssigning = false
      }
    )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  private assignTipFrom = () => {
    AppStateStore.showModalSpinner()
    this.submitting = true

    submitFormRequest(
      ApiClient.getInstance()
        .post(route(ApiRoutes.tips.assignFrom, { id: this.props.tip.id }), modelToSnakeCase(this.assignFromFormState.toObject())),
      this.assignFromFormState,
      this.assignFromFormErrors,
      response => {
        toast.success('Tip From Updated')

        this.props.tip.sourceMember = new Member().init(modelToCamelCase(response.data.tip.source_member))

        this.assignFromFormState.setAll({
          memberId: undefined,
        })

        this.isAssigningFrom = false
      }
    )
      .then(() => {
        AppStateStore.dismissModalSpinner()
        this.submitting = false
      })
  }

  @computed get isEditable () {
    return (this.props.tip.targetChapter && can(Permission.EditTip, { chapterId: this.props.tip.targetChapter.id }))
      || (this.props.tip.targetMemberId && can(Permission.EditTip, { memberId: this.props.tip.targetMemberId || undefined }))
  }

  @computed get isOwner () {
    return this.props.tip.sourceMemberId === safeNull(() => AuthStore.getUser()!.member!.id)
  }

  private onSaved = (tip: Tip) => {
    this.props.tip.datePassed = tip.datePassed
    this.props.tip.contactName = tip.contactName
    this.props.tip.contactPhone = tip.contactPhone
    this.props.tip.contactEmail = tip.contactEmail
    this.props.tip.contactCompany = tip.contactCompany
    this.props.tip.tipDetails = tip.tipDetails
    this.props.tip.status = tip.status
    this.props.tip.value = tip.value
    this.props.tip.tipType = tip.tipType
    this.props.tip.isPrivate = tip.isPrivate
    this.props.tip.socialMediaLink = tip.socialMediaLink
  }

  render (): React.ReactNode {
    const tip = this.props.tip

    return <div className={classNames(this.props.className, 'tip-card-wrapper', { 'is-selected': this.props.isChecked })}>
      <div className={classNames('tip-card', { 'is-selected': this.props.isChecked }, { 'is-extended-network-tip': !!tip.extendedNetworkMember },  { 'is-social-media-link': this.props.tip.socialMediaLink })}>
        <div className="d-flex align-items-center">
          <b className="flex-fill">
            Tip For {
            !this.isAssigning
              ? <RequirePermission permission={Permission.AssignTip} context={{ chapterId: tip.targetChapterId }}>
                {() => <a className="ml-2 print-hide" href="" onClick={ev => {
                  ev.preventDefault()
                  this.isAssigning = true
                }}><i className="fa fa-pencil"/></a>}
              </RequirePermission>
              : null
          }
          </b>
          <span className="text-muted">{tip.datePassed.format('MM/DD/YYYY')}</span>
          {
            this.props.showCheckbox
              ?
              <div className="ml-2 print-hide">
                <input type="checkbox"
                       name="email"
                       ref={this.checkboxRef}
                       onChange={ev => this.props.onChecked && this.props.onChecked(ev.target.checked)}
                       checked={this.props.isChecked || false}
                />
              </div>
              : null
          }
        </div>
        {
          !this.isAssigning
            ? tip.targetMember
              ? <MemberCell member={tip.targetMember}/>
              : <div>
                {tip.targetCategory.name}
              </div>
            : null
        }
        {
          this.isAssigning
            ? <div>
              <div>
                {
                  this.assignFormHelper.renderAsyncSelectInput({
                    label: 'Assign To',
                    loadOptions: loadMemberOptions(tip.targetChapterId),
                    name: 'memberId',
                  })
                }
              </div>
              <div className="d-flex">
                <div className="w-50 mr-4">
                  <Button
                    color="secondary"
                    block
                    onClick={() => {
                      this.assignFormState.setAll({
                        memberId: undefined
                      })
                      this.isAssigning = false
                    }}
                  >Cancel</Button>
                </div>
                <div className="w-50">
                  <ButtonLoader
                    loading={this.submitting}
                    color="primary"
                    block
                    onClick={() => {
                      this.assignTip()
                    }}
                  >Assign</ButtonLoader>
                </div>
              </div>
            </div>
            : null
        }
        <hr/>
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <div><b>Tip From{
              !this.isAssigningFrom
                ? <RequirePermission permission={Permission.AssignTip} context={{ chapterId: tip.sourceChapterId }}>
                  {() => <a className="ml-2 print-hide" href="" onClick={ev => {
                    ev.preventDefault()
                    this.isAssigningFrom = true
                  }}><i className="fa fa-pencil"/></a>}
                </RequirePermission>
                : null
            }</b></div>
            {
              !this.isAssigningFrom
                ? <MemberCell member={tip.sourceMember}/>
                : null
            }
            {
              this.isAssigningFrom
                ? <div>
                  <div>
                    {
                      this.assignFromFormHelper.renderAsyncSelectInput({
                        loadOptions: loadMemberOptions(tip.sourceChapterId),
                        name: 'memberId',
                      })
                    }
                  </div>
                  <div className="d-flex">
                    <div className="w-50 mr-4">
                      <Button
                        color="secondary"
                        block
                        onClick={() => {
                          this.assignFromFormState.setAll({
                            memberId: undefined
                          })
                          this.isAssigningFrom = false
                        }}
                      >Cancel</Button>
                    </div>
                    <div className="w-50">
                      <ButtonLoader
                        loading={this.submitting}
                        color="primary"
                        block
                        onClick={() => {
                          this.assignTipFrom()
                        }}
                      >Update</ButtonLoader>
                    </div>
                  </div>
                </div>
                : null
            }
          </div>
          {
            tip.extendedNetworkMember
              ? <div>
                <HelpTooltip
                  icon={ref => <i
                    style={{ fontSize: 22 }}
                    ref={ref}
                    className="flaticon-globe"
                  />}
                  content={<div>Extended Network<br/><b>{tip.extendedNetworkMember.name}</b></div>}
                />
              </div>
              : null
          }
        </div>
        <hr/>
        <div className="d-flex">
          <div className="w-50">
            <b>Person To Contact</b><br/>
            <div>{tip.contactName || '---'}</div>
          </div>
          <div className="w-50">
            <b>Phone</b>
            <div>{tip.contactPhone || '---'}</div>
          </div>
        </div>
        <hr/>
        <div>
          <b>Email</b>
          <div>{tip.contactEmail ? <a href={`mailto:${tip.contactEmail}`}>{tip.contactEmail}</a> : '---'}</div>
        </div>
        <hr/>
        <div>
          <b>Company</b>
          <div>{tip.contactCompany || '---'}</div>
        </div>
        <hr/>
        <div>
          <b>Details</b>
          <div>{tip.tipDetails ? nl2br(tip.tipDetails) : '---'}</div>
        </div>
        <hr/>
        <div>
          <b>Social Media Link</b>
          <div>{tip.socialMediaLink ? <a onClick={() => this.openInNewTab(tip.socialMediaLink)}>{tip.socialMediaLink}</a>: '---'}</div>
        </div>
        <hr />
        <div className="d-flex">
          <div className="w-50">
            <b>Tip Type</b>
            <div>{tip.tipType}</div>
          </div>
          <div className="w-50">
            <b>Value</b>
            <div>{(tip.value === undefined || tip.value === null) ? '---' : `$${formatNumber(tip.value, 2)}`}</div>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50">
            {
              tip.isPrivate
                ? <>
                  <b>Private Tip</b>
                  <div>Yes</div>
                </>
                : null
            }
          </div>
          <div className="w-50">
            <b>Status</b>
            <div>{tip.status || '---'}</div>
          </div>
        </div>
        {
          (this.isEditable || this.isOwner)
            ? <div className="mt-2">
              <div className="d-flex">
                <div className="w-50 pr-1">
                  {
                    this.isEditable
                      ? <Button
                        type="button"
                        color="primary"
                        block
                        onClick={() => {
                          this.showEditModal = true
                          this.renderEditModal = true
                          this.isAssigning = false
                        }}
                      >Edit Tip</Button>
                      : null
                  }
                </div>
                <div className="w-50 pl-1">
                  {
                    this.isOwner
                      ? <Button
                        type="button"
                        color="secondary"
                        block
                        onClick={() => {
                          this.renderDuplicateTipModal = true
                        }}
                      >Duplicate This Tip</Button>
                      : null
                  }
                </div>
              </div>
              {
                can(Permission.EditTipType)
                  ? <div className={'mt-2'}>
                    <Button
                      type="button"
                      color="danger"
                      block
                      onClick={() => {
                        AppStateStore.showConfirmationModal('Delete Tip', 'Are you sure you want to delete this tip? This is not reversible!', (result, modal) => {
                          modal.hide()

                          if (result) {
                            ;(async () => {
                              AppStateStore.showModalSpinner()
                              try {
                                await ApiClient.getInstance()
                                  .delete(route(ApiRoutes.tips.destroy, { id: this.props.tip.id }))
                                this.props.onInvalidated && this.props.onInvalidated()
                              } catch (err) {
                                AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err ? err.response : undefined))
                              }
                              AppStateStore.dismissModalSpinner()
                            })().then()
                          }
                        }, { type: 'danger' })
                      }}
                    >Delete This Tip</Button>
                  </div>
                  : null
              }
            </div>
            : null
        }
      </div>
      {
        this.renderEditModal
          ? <EditTipModal
            isOpen={this.showEditModal}
            toggle={() => this.showEditModal = false}
            tipId={this.props.tip.id}
            onClosed={() => this.renderEditModal = false}
            onSaved={(tip: Tip) => this.onSaved(tip)}
            isPrivate={tip.isPrivate}
          />
          : null
      }
      {
        (this.renderDuplicateTipModal && this.props.tip.targetMember)
          ? <TipFormModal
            isOpen={true}
            duplicateTip={this.props.tip}
            member={this.props.tip.targetMember}
            onCancel={() => this.renderDuplicateTipModal = false}
            toggle={() => this.renderDuplicateTipModal = false}
            onSaved={() => {
              this.renderDuplicateTipModal = false
            }}
          />
          : null
      }
    </div>
  }
}
