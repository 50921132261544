import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import * as React from "react"
import { SyntheticEvent } from "react"
import { observer } from "mobx-react"
import { BarLoader } from "react-spinners"
import { computed, observable } from "mobx"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import AppStateStore from "../stores/AppStateStore"
import Util, { modelToCamelCase, modelToSnakeCase } from "../common/Util"
import Tip from "../models/Tip"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"
import FormHelper from "../forms/FormHelper"
import { route } from "../routes/routes"
import ButtonLoader from "./ButtonLoader"
import { can, Permission } from './RequirePermission'
import HelpTooltip from './HelpTooltip'

type EditTipModalProps = {
  isOpen: boolean
  toggle: () => void
  tipId: number
  onSaved: (tip: Tip) => void
  onClosed: () => void
  isPrivate: boolean
}

@observer
export class EditTipModal extends React.Component<EditTipModalProps> {
  @observable private tip?: Tip

  private initialFormState = {
    date: '',
    tipType: '',
    contactName: '',
    contactCompany: '',
    contactPhone: '',
    contactEmail: '',
    tipDetails: '',
    status: '',
    value: '',
    isPrivate: false,
    socialMediaLink: '',
  }

  @observable private formState = new FormState(this.initialFormState)
  @observable private formErrors = new ErrorBag()
  @observable isPrivateBoxChecked = this.props.isPrivate;

  private formHelper = new FormHelper(this.formState, this.formErrors)

  @observable private submitting = false

  @computed get tipTypeOptions () {
    const options = []

    if (this.tip) {
      if (!this.tip.targetMemberId || this.tip.targetChapterId === this.tip.sourceChapterId) {
        // escrow/same chapter
        options.push({ value: 'Inside', text: 'Inside' })

        if (this.tip.targetMemberId) {
          options.push({ value: 'Outside', text: 'Outside' })
          options.push({ value: 'Social-Media', text: 'Social-Media' })
        }
      }

      if (this.tip.targetMemberId && this.tip.sourceChapterId !== this.tip.targetChapterId) {
        // not escrow
        options.push({ value: 'Inter-Chapter', text: 'Inter-Chapter' })
      }
    }

    return options
  }

  componentDidMount (): void {
    this.loadTip()
  }

  private loadTip = () => {
    if (!this.props.tipId) {
      return
    }

    ApiClient.query(`
    tip {
      *
    }
    `, {
      where: [{ id: this.props.tipId }]
    }).then(response => {
      this.tip = new Tip().init(response.data.tip)

      this.formState.setAll({
        date: this.tip.datePassed.format('MM/DD/YYYY'),
        tipType: this.tip.tipType,
        contactName: this.tip.contactName,
        contactCompany: this.tip.contactCompany,
        contactPhone: this.tip.contactPhone,
        contactEmail: this.tip.contactEmail,
        tipDetails: this.tip.tipDetails,
        status: this.tip.status || '',
        value: this.tip.value || '',
        isPrivate: this.tip.isPrivate,
        socialMediaLink: this.tip.socialMediaLink,
      })
    })
      .catch(error => {
        this.props.toggle()
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response))
      })
  }

  componentDidUpdate (prevProps: Readonly<EditTipModalProps>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.tipId !== this.props.tipId) {
      this.tip = undefined
      this.loadTip()
    }
  }

  private submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.formErrors.clearErrors()
    AppStateStore.showModalSpinner()
    this.submitting = true

    ApiClient.getInstance().put(route(ApiRoutes.tips.update, { id: this.tip!.id }), modelToSnakeCase(this.formHelper.toObject()))
      .then(response => {
        this.props.onSaved(new Tip().init(modelToCamelCase(response.data.tip)))
        this.props.toggle()
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => {
        this.submitting = false
        AppStateStore.dismissModalSpinner()
      })
  }

  render (): React.ReactNode {
    const { isOpen, toggle } = this.props

    return <Modal isOpen={isOpen} onClosed={this.props.onClosed} size="lg" toggle={toggle}>
      <ModalHeader toggle={toggle}>
        Edit Tip
      </ModalHeader>
      <ModalBody>
        {
          this.tip
            ? <form onSubmit={this.submit}>
              <div className="form-row">
                <div className="col-sm-6">
                  {this.formHelper.renderDatePickerInput({
                    name: 'date',
                    label: 'Date',
                  })}
                </div>
                <div className="col-sm-6">
                  {this.formHelper.renderSelectInput({
                    name: 'tipType',
                    label: 'Tip Type',
                    disabled: !can(Permission.EditTipType, {chapterId: this.tip.targetChapterId}),
                    options: this.tipTypeOptions,
                  })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-6">
                  {this.formHelper.renderTextInput({
                    name: 'contactName',
                    label: 'Person To Contact',
                  })}
                </div>
                <div className="col-sm-6">
                  {this.formHelper.renderTextInput({
                    name: 'contactCompany',
                    label: 'Company Name',
                  })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-6">
                  {this.formHelper.renderTextInput({
                    name: 'contactPhone',
                    label: 'Phone Number',
                    type: 'tel',
                  })}
                </div>
                <div className="col-sm-6">
                  {this.formHelper.renderTextInput({
                    name: 'contactEmail',
                    label: 'Email Address',
                    type: 'email',
                  })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-12">
                  {this.formHelper.renderTextAreaInput({
                    name: 'tipDetails',
                    label: 'Tip Details',
                  })}
                </div>
              </div>
              {/* <div className="form-row">
                {this.tip.socialMediaLink ?
                  (<><div className="col-sm-12">
                    {this.formHelper.renderTextAreaInput({
                      name: 'socialMediaLink',
                      label: 'Social Media Link',
                    })}
                  </div></>) : null
                }
              </div> */}
              <div className="form-row">
                <div className="col-6">
                  {this.formHelper.renderTextInput({
                    label: 'Value',
                    prepend: '$',
                    name: 'value',
                  })}
                </div>
                <div className="col-6">
                  {this.formHelper.renderSelectInput({
                    label: 'Status',
                    name: 'status',
                    options: [
                      'New',
                      'Bogus',
                      'Invalid',
                      'Closed',
                      'Working',
                    ].map(s => ({ value: s, text: s }))
                  })}
                </div>
              </div>
              <div className="form-row">
                <div className="col-sm-12">
                  <div style={{ display: 'flex' }}>
                    <div>
                      {this.formHelper.renderCheckboxInput({
                        name: 'isPrivate',
                        label: 'This is a semi-private tip',
                        onChange: () => this.isPrivateBoxChecked = !this.isPrivateBoxChecked,
                      })}
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <HelpTooltip
                        placement="right"
                        content="Checking this box will hide contact info and tip details from other chapter members"
                      />
                    </div>
                  </div>
                  <div  style={{padding:'5px', marginBottom:'20px', border:'1px solid transparent', borderRadius:'4px', backgroundColor:'#fcf8e3', borderColor:'#faebcc',color:'#8a6d3b',width:'80%', display:this.isPrivateBoxChecked==false?'none':'block' }}>
                      All the Tip data will be visible to Board members, please do not add any sensitive info.
                    </div>
                </div>
              </div>
              <div className="form-buttons">
                <Button color="secondary" onClick={() => this.props.toggle()}>Cancel</Button>
                <ButtonLoader type="submit" color="primary" onClick={this.submit} loading={this.submitting}>Save Changes</ButtonLoader>
              </div>
            </form>
            : <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
        }
      </ModalBody>
    </Modal>
  }
}
