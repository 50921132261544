import * as React from "react"
import { observer } from "mobx-react"
import AuthStore from "../stores/AuthStore"
import AuthUser, { UserRole } from "../models/AuthUser"
import Event, { EventStatus, EventType } from '../models/Event'
import * as _ from "lodash"
import { DocumentLibraryDescriptor, LibraryType } from "../models/DocumentLibraryItem"

type PermissionRender = (allowed: boolean) => React.ReactNode
type PermissionContext = {
  chapterId?: number
  memberId?: number
  regionId?: number
  documentLibraryDescriptor?: DocumentLibraryDescriptor
  event?: Event
}
type Props = {
  permission: Permission | Permission[]
  require: PermissionOperator
  context: PermissionContext
  renderIfDenied: boolean
  children: PermissionRender
}

export enum PermissionOperator {
  All,
  Any,
}

export enum Permission {
  CreateMember,
  EditMember,
  ViewMemberBilling,
  ManageMembership,
  CreateMembership,
  EditMembership,
  ChangeMembershipAutoRenew,
  ViewMemberPaymentMethods,
  EditMemberBadgeColor,
  EditMemberNetwork,
  PerformMemberActions,
  ViewMemberShippingHistory,
  ChangeMemberName,
  ChangeBusinessName,
  ViewMemberUsageStats,
  EditMemberProfile,

  EditTip,
  EditTipType,
  SendTipAsMember,

  CreateUser,

  CreateRegion,

  CreateArea,

  CreateSupportArea,

  CreateChapter,
  EditChapter,
  ViewChapterTips,
  ManageChapterCalendar,
  EditEvent,
  ManageEvent,
  CancelEvent,
  ViewChapterPaymentMethods,
  ViewChapterBilling,
  CreateChapterInvoice,
  CreateMemberInvoice,

  CreateCategory,

  ManageGuests,
  EditGuests,
  EditGuestBankCode,
  SendApplication,
  ViewApplication,
  EditApplicationMember,
  DownloadApplicationPdf,
  ArchiveApplication,
  ArchiveMemberApplication,
  ExportChapterTips,

  EditSupportArea,

  ViewSystemSettings,
  ViewUsers,

  ManageCalendar,

  AccessDevTools,

  ManageDocumentLibrary,

  AdministerMember,
  AdministerChapter,
  ImpersonateMember,

  SeeSuperAdminTools,
  SeeAdminTools,
  SeeAreaRepTools,
  SeeDailyRevenueReport,
  SeeMemberLossReport,
  AccessCustomReports,
  SeeFulfillmentList,
  SeeTransactionsList,


  SeeBoardMemberTools,
  ManageAttendance,
  ManageBoardApplications,
  ViewBoardReports,
  ManageProgram,
  ManageChapterDocumentLibrary,
  ManageEscrowTips,
  AssignTip,
  SendInvoice,
  ManageChapterFees,
  ManageChapter,

  RequestMemberResignation,
  ViewAllGuests,
  ViewSentSystemMessages,

  ApproveApplicationWithoutPayment,

  SeeDashboard,
  EditMemberTestimony
}

export function can (permission: Permission | Permission[], context?: PermissionContext, require: PermissionOperator = PermissionOperator.All, user?: AuthUser): boolean {
  const permissions: Permission[] = _.isArray(permission) ? permission : [permission]

  if (!user) {
    user = AuthStore.getUser()
  }

  if (user) {
    // check all requested permissions
    const can = permissions.map(p => {
      if (!user) {
        return false
      }

      if (user.hasAnyRole([UserRole.Admin, UserRole.Employee])) {
        // admin and employees can do everything except the things listed below
        if ([
          Permission.SeeBoardMemberTools,
          Permission.SeeAreaRepTools,
        ].indexOf(p) === -1) {
          return true
        }
      }

      if (user.isRegionAdmin || user.isAreaRep) {
        if ([
          Permission.ViewAllGuests,
          Permission.ManageCalendar,
          Permission.DownloadApplicationPdf,
          Permission.ArchiveApplication,
          Permission.SeeDashboard,
        ].indexOf(p) > -1) {
          return true
        }

        // region admin & area rep permissions
        if ([
          Permission.EditChapter,
          Permission.AdministerMember,
          Permission.AdministerChapter,
          Permission.EditMemberNetwork,
          Permission.EditMember,
          Permission.PerformMemberActions,
          Permission.EditEvent,
          Permission.ManageEvent,
        ].indexOf(p) > -1) {
          if (user.isRegionAdmin) {
            if (context && context.regionId && user.adminRegionIds.indexOf(context.regionId) > -1) {
              // this chapter is in the region admin's region
              return true
            }
          } else if (user.isAreaRep) {
            if (context && context.chapterId && user.supportAreaChapters.indexOf(context.chapterId) > -1) {
              // this chapter is in the rep's area
              return true
            }
          }
        }

        if (user.isAreaRep) {
          // global area rep permissions
          if ([
            Permission.SeeAreaRepTools,
          ].indexOf(p) > -1) {
            return true
          }
        }

        if (user.isRegionAdmin) {
          // global region admin permissions
          if ([
            Permission.SeeDailyRevenueReport,
            Permission.SeeMemberLossReport,
            Permission.SeeAdminTools,
            Permission.ManageMembership,
            Permission.ViewMemberPaymentMethods,
            Permission.ViewMemberBilling,
          ].indexOf(p) > -1) {
            return true
          }
        }

        // scoped region admin permissions
        if ([
          Permission.ImpersonateMember,
        ].indexOf(p) > -1) {
          if (user.isRegionAdmin) {
            if (context && context.regionId && user.adminRegionIds.indexOf(context.regionId) > -1) {
              // this chapter is in the region admin's region
              return true
            }
          }
        }
      }

      if (user.isBoardMember) {
        // list of all board member permissions
        if ([
          Permission.SendTipAsMember,
          Permission.SeeBoardMemberTools,
          Permission.ManageAttendance,
          Permission.ManageBoardApplications,
          Permission.ViewBoardReports,
          Permission.ManageProgram,
          Permission.ManageChapterDocumentLibrary,
          Permission.EditGuests,
          Permission.DownloadApplicationPdf,
          Permission.ArchiveApplication,
          Permission.ManageChapter,
          Permission.EditTipType,
        ].indexOf(p) > -1) {
          return true
        }

        // list of all board member chapter-specific permissions
        if ([
          Permission.ViewChapterTips,
          Permission.ManageChapterCalendar,
          Permission.ViewApplication,
          Permission.EditChapter,
          Permission.RequestMemberResignation,
          Permission.ManageChapterFees,
          Permission.ArchiveMemberApplication,
          Permission.EditMemberProfile,
          Permission.EditMember,
          Permission.ExportChapterTips,
        ].indexOf(p) > -1 && user.chapter && context && context.chapterId === user.chapter.id) {
          return true
        }

        if ([
          Permission.ManageEvent,
        ].indexOf(p) > -1) {
          // board members can manage business mixer and other event types
          if (
            context && context.event && context.event.chapterId === user.chapter!.id
            && context.event.isCustomEvent
          ) {
            return true
          }
        }

        if ([
          Permission.EditEvent,
        ].indexOf(p) > -1) {
          // board members can edit events for their own chapter
          if (
            context && context.event && context.event.chapterId === user.chapter!.id
          ) {
            return true
          }
        }

        if ([
          Permission.CancelEvent,
        ].indexOf(p) > -1) {
          // board members can cancel chapter meetings for their own chapter
          if (
            context && context.event && context.event.chapterId === user.chapter!.id
            && context.event.eventType === EventType.ChapterMeeting
            && context.event.status === EventStatus.Active
          ) {
            return true
          }
        }
      }

      if (user.isBoardPosition('president')) {
        // list of all president permissions
        if ([
          Permission.ViewChapterBilling,
          Permission.ViewChapterPaymentMethods,
        ].indexOf(p) > -1) {
          return true
        }
      }

      if (user.isBoardPosition('treasurer')) {
        // list of all treasurer permissions
        if ([
          Permission.ViewChapterBilling,
          Permission.ViewChapterPaymentMethods,
        ].indexOf(p) > -1) {
          return true
        }
      }

      if (user.isChairPosition('program_director')) {
        // list of all program director permissions
        if ([
          Permission.ManageProgram,
          Permission.SeeBoardMemberTools,
        ].indexOf(p) > -1) {
          return true
        }
      }

      if (user.isChairPosition('inspector')) {
        // list of all inspector permissions
        if ([
          Permission.SeeBoardMemberTools,
          Permission.ManageBoardApplications,
        ].indexOf(p) > -1) {
          return true
        }
      }

      if (user.isChairPosition('inspector2')) {
        // list of all inspector2 permissions
        if ([
          Permission.SeeBoardMemberTools,
          Permission.ManageBoardApplications,
        ].indexOf(p) > -1) {
          return true
        }
      }

      if (user.isChairPosition('tip_committee')) {
        // list of all tip committee permissions
        if ([
          Permission.SendTipAsMember,
          Permission.SeeBoardMemberTools,
          Permission.ViewBoardReports,
        ].indexOf(p) > -1) {
          return true
        }

        if (user.chapter && context && context.chapterId === user.chapter.id) {
          // list all tip committee permissions for their own chapter
          if ([
            Permission.EditTip,
            Permission.ManageEscrowTips,
            Permission.AssignTip,
            Permission.EditTipType,
            Permission.ExportChapterTips,
          ].indexOf(p) > -1) {
            return true
          }
        }
      }

      if (
        user.isBoardPosition('president')
        || user.isBoardPosition('vice_president')
        || user.isBoardPosition('tip_master')
        || user.isChairPosition('tip_committee')
      ) {
        // list of all tip master permissions
        if ([
          Permission.ManageEscrowTips,
          Permission.SendTipAsMember,
        ].indexOf(p) > -1) {
          return true
        }

        if (user.chapter && context && context.chapterId === user.chapter.id) {
          // list all tip master permissions for their own chapter
          if ([
            Permission.ManageEscrowTips,
            Permission.EditTip,
            Permission.EditTipType,
            Permission.AssignTip,
          ].indexOf(p) > -1) {
            return true
          }
        }
      }

      if (user.member && context) {
        if (user.member && user.member.id === context.memberId) {
          if ([
            // list of all member permissions
            Permission.EditMember,
            Permission.ViewMemberPaymentMethods,
            Permission.ViewMemberBilling,
            Permission.ChangeMembershipAutoRenew,
            Permission.EditTip,
            Permission.PerformMemberActions,
            Permission.RequestMemberResignation,
          ].indexOf(p) > -1) {
            return true
          }
        }
      }

      if (p === Permission.ManageDocumentLibrary && context) {
        const libraryDescriptor = context.documentLibraryDescriptor

        if (libraryDescriptor) {
          if (
            libraryDescriptor.libraryType === LibraryType.NetworkDocumentLibrary
            || libraryDescriptor.libraryType === LibraryType.CorporateDocumentLibrary
            || libraryDescriptor.libraryType === LibraryType.NetworkVideoLibrary

          ) {
            // only admins can manage network and corporate document libraries
            return user.hasAnyRole([UserRole.Admin, UserRole.Employee])
          } else if (libraryDescriptor.libraryType === LibraryType.ChapterDocumentLibrary) {
            // only board members can manage chapter library
            return user.isBoardMember && user.chapter && user.chapter.id === libraryDescriptor.libraryOwnerId
          } else if (libraryDescriptor.libraryType === LibraryType.MemberDocumentLibrary) {
            // only member can manage their own library
            return user.member && user.member.id === libraryDescriptor.libraryOwnerId
          }
        }
      }

      // don't have permission
      return false
    })

    if (require === PermissionOperator.All) {
      // make sure there are no failed permission checks
      return can.filter(c => !c).length === 0
    } else if (require === PermissionOperator.Any) {
      // make sure there is at least 1 passed permission check
      return can.filter(c => c).length > 0
    } else {
      return false
    }
  }

  return false
}

@observer
export default class RequirePermission extends React.Component<Props, never> {
  static defaultProps = {
    require: PermissionOperator.All,
    context: {},
    renderIfDenied: false,
  }

  render (): React.ReactNode {
    const allow = can(this.props.permission, this.props.context, this.props.require)

    return (allow || this.props.renderIfDenied) ? this.props.children(allow) : null
  }
}
