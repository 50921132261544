import React, { SyntheticEvent } from 'react'
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ButtonLoader from '../ButtonLoader'
import { observer } from 'mobx-react'
import { computed, observable } from 'mobx'
import { BarLoader } from 'react-spinners'
import FormState from '../../common/FormState'
import ErrorBag from '../../common/ErrorBag'
import FormHelper from '../../forms/FormHelper'
import SignatureCanvas from "react-signature-canvas"
import moment from 'moment-timezone'
import ApiClient, { ApiRoutes } from '../../api/ApiClient'
import Util, { formatCurrency, modelToCamelCase, modelToSnakeCase } from '../../common/Util'
import AppStateStore from '../../stores/AppStateStore'
import { route } from '../../routes/routes'
import AddPaymentMethodModal from '../AddPaymentMethodModal'
import { loadCategoryOptions } from '../../api/AsyncHelpers'
import FormError from '../FormError'

type Props = {
  onClosed: () => void
  chapterId: number
  memberId: number
}

type PaymentMethodOption = {
  id: number
  description: string
}

type CategoryChangeData = {
  categoryChangeFeeTotal: number
  paymentMethods: PaymentMethodOption[]
  isAutoRenewalEnabled: boolean
  isCompanyMembership: boolean
  emailAddress: string | null
  businessName: string
}

@observer
export default class RequestCategoryChangeModal extends React.Component<Props> {
  @observable private isOpen = true
  @observable private loading = false
  @observable private submitting = false
  @observable private showCategoryWarning = false
  @observable private showConfirmation = false

  @observable private categoryChangeData?: CategoryChangeData

  @observable private renderAddPaymentMethodModal = false
  @observable private showAddPaymentMethodModal = false

  @computed
  private get paymentMethodOptions () {
    return this.categoryChangeData
      ? this.categoryChangeData.paymentMethods.map(pm => ({
        value: String(pm.id),
        text: pm.description,
      }))
      : []
  }

  @observable private formState = new FormState({
    categoryId: undefined,
    paymentMethodId: undefined,
    enableAutoRenewal: false,
    businessName: '',
    emailAddress: '',
  })
  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private applicationSignatureRef = React.createRef<ApplicationSignature>()

  componentDidMount (): void {
    this.loadCategoryChangeData()
  }

  protected loadCategoryChangeData = () => {
    this.loading = true

    ApiClient.getInstance()
      .get(route(ApiRoutes.members.getCategoryChangeData, { id: this.props.memberId }))
      .then(response => {
        this.categoryChangeData = modelToCamelCase(response.data) as CategoryChangeData

        if (!this.formState.get('paymentMethodId') && this.paymentMethodOptions.length) {
          this.formState.set('paymentMethodId', this.paymentMethodOptions[0].value)
        }

        this.formState.set('emailAddress', this.categoryChangeData.emailAddress || '')
        this.formState.set('businessName', this.categoryChangeData.businessName || '')

        if (!this.categoryChangeData.isAutoRenewalEnabled) {
          this.formState.set('enableAutoRenewal', true)
        }
      })
      .catch(err => {
        this.isOpen = false
        AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response))
      })
      .then(() => this.loading = false)
  }

  private toggle = () => {
    if (!this.submitting) {
      this.isOpen = false
    }
  }

  protected submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    AppStateStore.showModalSpinner()
    this.formErrors.clearErrors()

    const applicationSignatureImageData = this.applicationSignatureRef.current!.toDataURL()

    const submitData = {
      ...this.formHelper.toObject(),
      applicationSignatureImageData,
      categoryChangeFeeTotal: this.categoryChangeData!.categoryChangeFeeTotal,
    }

    ApiClient.getInstance().post(route(ApiRoutes.members.requestCategoryChange, { id: this.props.memberId }), modelToSnakeCase(submitData))
      .then(() => {
        this.showConfirmation = true
      })
      .catch(error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
      })
      .then(() => {
        this.submitting = false
        AppStateStore.dismissModalSpinner()
      })
  }

  private checkCategoryAvailability = () => {
    if (this.props.chapterId && this.formState.get('categoryId')) {
      ApiClient.getInstance()
        .get(route(ApiRoutes.chapters.checkCategoryAvailability, { id: this.props.chapterId, categoryId: this.formState.get('categoryId') }))
        .then(response => this.showCategoryWarning = !response.data.available)
        .catch(() => this.showCategoryWarning = false)
    } else {
      this.showCategoryWarning = false
    }
  }

  protected renderApplication = () => {
    return <>
      <Alert color="info">
        To request a change to your primary business category, choose a new category below and your category change application will be submitted to your chapter board. Once approved, your payment method will be charged and your category will be updated.
      </Alert>

      <form onSubmit={this.submit}>
        <div className="form-row">
          <div className="col-12" style={{ zIndex: 2000 }}>
            {this.formHelper.renderAsyncSelectInput({
              label: 'New Category',
              name: 'categoryId',
              loadOptions: loadCategoryOptions(),
              onChange: () => this.checkCategoryAvailability(),
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-6">
            {this.formHelper.renderTextInput({
              label: 'Business Name',
              name: 'businessName',
            })}
          </div>
          <div className="col-6">
            {this.formHelper.renderTextInput({
              label: 'Business Email',
              name: 'emailAddress',
            })}
          </div>
        </div>
        {
          this.showCategoryWarning
            ? <Alert color="warning">The category you have selected is already filled for this chapter. Are you sure you want to choose this category?</Alert>
            : null
        }
        <div className="callout-box text-right mb-4 mt-2">
          Category Change Fee: {formatCurrency(this.categoryChangeData!.categoryChangeFeeTotal)} USD
        </div>
        <div className="form-row">
          <div className="col-12">
            <label>Payment Method</label>
            {this.formHelper.renderSelectInput({
              name: 'paymentMethodId',
              options: this.paymentMethodOptions,
              append: <Button
                type="button"
                color="primary"
                onClick={() => {
                  this.showAddPaymentMethodModal = true
                  this.renderAddPaymentMethodModal = true
                }}>Add Payment Method</Button>,
              appendContent: true,
            })}
          </div>
        </div>
        {
          !this.categoryChangeData!.isAutoRenewalEnabled
            ? <div className="form-row">
              <div className="col-12">
                {
                  this.formHelper.renderCheckboxInput({
                    label: 'Enable automatic renewals for my LeTip membership',
                    name: 'enableAutoRenewal',
                  })
                }
                {
                  this.formState.get('enableAutoRenewal')
                    ? <p>
                      I agree to have my LeTip membership automatically renew at the current rate.
                      My membership will renew annually on the month that my membership application is approved by LeTip International, Inc.
                      I acknowledge that LeTip International, Inc. reserves the right to increase membership dues; I understand that I will be given not less than 30 day notice of any such increase.
                      <b>If I cancel the automatic renewal option for my membership, I will enter my request into LeTip Wired&trade; or I must send a written notice, not less than 30 days, before cancellation to: LeTip International, Inc., 7895 West Sunset Road, Suite 101, Las Vegas, NV 89113.</b>
                    </p>
                    : null
                }
              </div>
            </div>
            : null
        }
      </form>
      {
        this.renderAddPaymentMethodModal
          ? <AddPaymentMethodModal
            isOpen={this.showAddPaymentMethodModal}
            toggle={() => this.showAddPaymentMethodModal = false}
            onClosed={() => this.renderAddPaymentMethodModal = false}
            onSaved={paymentMethod => {
              this.categoryChangeData!.paymentMethods.push({
                id: paymentMethod.id,
                description: paymentMethod.description,
              })
              this.formState.set('paymentMethodId', String(paymentMethod.id))
              this.showAddPaymentMethodModal = false
            }}
            memberId={this.props.memberId}
          />
          : null
      }

      <div className="application-subheader">Application Signature</div>
      <p>
        My signature below attests that I understand that <b>LeTip International dues are non-refundable</b>,
        and that I have read, understand, and agree to abide by LeTip International's Application Agreement,
        fee structure and program requirements. I also understand that if I resign from LeTip, or my membership
        is terminated by the board of directors or by a LeTip International representative, <b>my membership
        and renewal dues are non-refundable.</b>
      </p>

      <div>
        <ApplicationSignature
          ref={this.applicationSignatureRef}
        />
        <FormError errors={this.formErrors} fieldName="applicationSignatureImageData"/>
      </div>
    </>
  }

  private renderConfirmation = () => {
    return <div>
      <p>
        Your Category Change Application will be reviewed and approved by your Chapter Board.<br/><br/>
        Your chapter board have received an email letting them know of your request, but you should also reach out to your chapter president to explain the change.

      </p>
    </div>
  }

  render () {
    return <Modal isOpen={this.isOpen} toggle={this.toggle} size="lg" onClosed={this.props.onClosed}>
      <ModalHeader toggle={this.toggle}>
        Request Category Change
      </ModalHeader>
      <ModalBody>
        {
          this.showConfirmation
            ? this.renderConfirmation()
            : this.loading || !this.categoryChangeData
              ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
              : this.renderApplication()
        }
      </ModalBody>
      <ModalFooter>
        {
          this.showConfirmation
            ? <>
              <Button color="secondary" onClick={this.toggle}>OK</Button>
            </>
            : <>
              <Button color="secondary" disabled={this.submitting} onClick={this.toggle}>Cancel</Button>
              <ButtonLoader type="button" color="primary" loading={this.submitting} onClick={this.submit}>Submit Request</ButtonLoader>
            </>
        }
      </ModalFooter>
    </Modal>
  }
}

@observer
class ApplicationSignature extends React.Component {
  private canvasRef = React.createRef<SignatureCanvas>()

  toDataURL (): string {
    return (this.canvasRef.current as any).toDataURL("image/png")
  }

  render (): React.ReactNode {
    return <div className="signature-container">
      <div className="signature-box">
        <SignatureCanvas
          canvasProps={{ className: 'signature-canvas' }}
          ref={this.canvasRef}
        />
        <label>Sign with your mouse</label>
      </div>
      <div className="clear-signature">
        <Button
          color="secondary"
          onClick={() => (this.canvasRef.current as any)!.clear()}
        >Clear Signature</Button>
      </div>
      <div className="signature-date">
        <span>{moment().format('MMMM DD, YYYY')}</span>
      </div>
      <div className="label-tab">
        Sign Here
        <div className="label-tab-arrow"/>
      </div>
    </div>
  }
}
