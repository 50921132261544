import * as React from "react"
import { Button } from "reactstrap"
import { computed, observable } from "mobx"
import { observer } from "mobx-react"
import BaseView from "../BaseView"
import Conversation from "../../models/Conversation"
import Util, { createLazyResource } from "../../common/Util"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import AuthStore from "../../stores/AuthStore"
import LazyResourcePanel from "../../components/LazyResourcePanel"
import SendMessageModal from "../../components/messaging/SendMessageModal"
import _ from 'lodash';
import EventBus, { EventBusContext } from "../../common/EventBus"
import ConversationList from "../../components/messaging/ConversationList"
import ConversationMessageList from "../../components/messaging/ConversationMessageList"
import ConversationParticipantsList from "../../components/messaging/ConversationParticipantsList"
import { toast } from "react-toastify"
import AppStateStore from "../../stores/AppStateStore"
import ErrorBag from "../../common/ErrorBag"
import ButtonLoader from "../../components/ButtonLoader"
import FormError from "../../components/FormError"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { RouteComponentProps, withRouter } from "react-router"

type Props = {
  match: {
    params: {
      conversationId: number
    }
  }
} & RouteComponentProps

@observer
class MessagesView extends BaseView<Props> {
  @observable private showSendMessageModal = false
  @observable private renderSendMessageModal = false

  @observable private conversations = createLazyResource<Conversation[]>(() => {
    return ApiClient.query(`
conversations {
  id
  groupName
  participantsCache
  isBroadcast
  isDeleted

  lastMessage {
    id
    createdAt
    message
  }
}
    `, {
      where: [
        { _scope: 'visibleToUser', value: AuthStore.getUser()!.id },
        { is_deleted: 0}
      ],
      order: [
        { id: 'lastMessage.createdAt', desc: true },
      ],
    })
  }, response => response.data.conversations.map((t: {}) => new Conversation().init(t)))

  @observable private selectedConversationId?: number

  @computed get selectedConversation () {
    const selectedConversationId = this.selectedConversationId // need this to trigger compute

    return (this.conversations.current && this.conversations.current.length)
      ? selectedConversationId
        ? _.find(this.conversations.current, c => c.id === selectedConversationId)
        : this.conversations.current[0]
      : undefined
  }

  private setSelectedConversationId (id: number) {
    this.selectedConversationId = id
    this.message = ''
    this.props.history.replace(route(Routes.member.messages, { conversationId: id }))
  }

  private eventBus = new EventBus()

  private onMessagesInvalidated = () => {
    this.conversations.invalidate()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any): void {
    if (this.props.match.params.conversationId != prevProps.match.params.conversationId) {
      this.selectedConversationId = Number(this.props.match.params.conversationId)
    }
  }

  componentDidMount (): void {
    super.componentDidMount()

    if (this.props.match.params.conversationId) {
      this.selectedConversationId = Number(this.props.match.params.conversationId)
    }

    this.eventBus.on('messages-invalidated', () => this.onMessagesInvalidated())
  }

  componentWillUnmount (): void {
    super.componentWillUnmount()

    this.eventBus.remove(this.onMessagesInvalidated)
  }

  @observable private submitting = false
  @observable private formErrors = new ErrorBag()
  @observable private message: string = ''

  private sendReply = () => {
    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance().post(ApiRoutes.messaging.sendReply, {
      conversation_id: this.selectedConversation!.id,
      message: this.message,
    })
      .then(() => {
        toast.success('Message has been sent')
        this.message = ''
        this.eventBus.dispatch('messages-invalidated')
      })
      .catch(error => Util.handleErrorResponse(error.response, this.formErrors, undefined, (response, message) => {
        AppStateStore.showAlertModal('Error', message, m => {
          m.hide()
        })
        return true
      }))
      .then(() => {
        this.submitting = false
      })
  }

  renderContentHeader (): React.ReactNode | null {
    return (
      <>
        <h1>Messages</h1>
        <ul className="content-header-actions">
          <li>
            <Button color="primary" onClick={() => {
              this.showSendMessageModal = true
              this.renderSendMessageModal = true
            }}><i className="fa fa-send"/> Create a New Message</Button>
          </li>
        </ul>
      </>
    )
  }

  renderContentBody () {
    return <>
      <EventBusContext.Provider value={{ eventBus: this.eventBus }}>
        <div className="d-flex" style={{
          margin: '-1.25rem',
        }}>
          <div className="messages-conversation-list">
            <LazyResourcePanel resource={this.conversations} emptyMessage={<div style={{ padding: 20 }}>You don't have any messages</div>}>
              {conversations => <ConversationList
                conversations={conversations}
                selectedConversationId={this.selectedConversation ? this.selectedConversation.id : undefined}
                onConversationSelected={conversation => this.setSelectedConversationId(conversation.id)}
              />}
            </LazyResourcePanel>
          </div>
          <div className="messages-message-list">
            {
              this.selectedConversation
                ? <>
                  <ConversationParticipantsList conversation={this.selectedConversation}/>
                  {
                    this.selectedConversation.isBroadcast
                      ? null
                      : <div className="d-flex align-items-end">
                        <div className="flex-fill mr-4">
                      <textarea
                        className="form-control"
                        value={this.message}
                        onChange={ev => this.message = ev.target.value}
                        disabled={this.submitting}
                      />
                        </div>
                        <div>
                          <ButtonLoader
                            type="button"
                            color="success"
                            onClick={this.sendReply}
                            loading={this.submitting}
                          >Send Reply</ButtonLoader>
                        </div>
                      </div>
                  }
                  <FormError errors={this.formErrors} fieldName="message"/>
                  <hr/>
                  <ConversationMessageList
                    conversation={this.selectedConversation}
                  />
                </>
                : null
            }
          </div>
        </div>
        {
          this.renderSendMessageModal
            ? <SendMessageModal
              isOpen={this.showSendMessageModal}
              onClosed={() => {
                this.renderSendMessageModal = false
              }}
              toggle={() => this.showSendMessageModal = false}
            />
            : null
        }
      </EventBusContext.Provider>
    </>
  }
}

export default withRouter(MessagesView)
