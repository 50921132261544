import BaseView from "../BaseView"
import { observer } from "mobx-react"
import * as React from "react"
import { LinkContainer } from "react-router-bootstrap"
import { route } from "../../routes/routes"
import { Routes } from "../../routes/AppRoutes"
import { Button, ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader } from "reactstrap"
import { TIPS_VIEW_TABS } from "./MemberTipsView"
import { observable } from "mobx"
import { InviteGuestModal } from "../../components/InviteGuestModal"
import { createLazyResource, formatNumber, modelToCamelCase, nl2br, normalizeUrl } from "../../common/Util"
import ApiClient, { ApiRoutes } from "../../api/ApiClient"
import Member from "../../models/Member"
import AuthStore from "../../stores/AuthStore"
import LazyResourcePanel from "../../components/LazyResourcePanel"
import { BarLoader } from "react-spinners"
import classNames from 'classnames'
import AddressView from "../../components/AddressView"
import { Link } from "react-router-dom"
import MapView from "../../components/MapView"
import * as _ from "lodash"
import Membership from "../../models/Membership"
import VideoPlayer from "../../components/VideoPlayer"
import { TABS as MEMBER_PROFILE_TABS } from './MemberProfilesView'
import EventBus, { EventBusContext } from "../../common/EventBus"
import BankCode from '../../components/BankCode'
import BankCodeLink from '../../components/BankCodeLink'
import BankEbookLink from '../../components/BankEbookLink'
import SupportAreaUser from '../../models/SupportAreaUser'
import MemberProfileImage from '../../components/MemberProfileImage'
import Chapter from '../../models/Chapter'
import pluralize from "pluralize"
import Config from '../../common/Config'
import { ScheduleNtsTrainingModal } from '../../components/member/ScheduleNtsTrainingModal'
import { ScheduleBoardTrainingModal } from "../../components/member/ScheduleBoardTrainingModal"
import NtsBadgeView from "../../components/badge/ntsbadgeview"

@observer
export default class MemberDashboardView extends BaseView {
  @observable
  private showInviteGuestModal = false

  @observable private renderScheduleNtsEventModal = false
  @observable private renderScheduleBoardTrainingEventModal = false


  @observable private member = createLazyResource<Member>(() => {
    return ApiClient.query(
      `
member {
  *
  crackMyCodeLandingUrl
  codebreakerEbookUrl

  category {
    *
  }

  business {
    *
    address {
      *
    }
  }

  chapter {
    *

    area {
      *
    }
  }
}
      `,
      {
        where: [{ id: AuthStore.getUser()!.member!.id }]
      }
    )
  }, response => new Member(response.data.member))

  componentDidMount(): void {
    super.componentDidMount()
    this.member.refresh().then(() => {
    })
  }

  renderContentHeader(): React.ReactNode | null {
    return <>
      <h1>Dashboard</h1>
      <ul className="content-header-actions">
        {
          this.member.current && !this.member.current.ntsTrainingCompletedAt
            ? <>
              <li style={{ flex: 1, textAlign: !this.member.current.boardTrainingCompletedAt ? 'right' : 'center' }}><Button color="primary" onClick={() => this.scheduleNtsTraining()}>Schedule NTS Training</Button></li>
            </>
            : null
        }
        {
          this.member.current && !this.member.current.boardTrainingCompletedAt
            ? <>
              <li style={{ flex: 1, textAlign: !this.member.current.ntsTrainingCompletedAt ? 'left' : 'center' }}><Button color="primary" onClick={() => this.scheduleBoardTraining()}>Schedule Board Training</Button></li>
            </>
            : null
        }

        {/* Convention registration button on member dashboard */}
        {/* {
          this.member.current
            ? <>
              <li style={{ flex: 1, textAlign: !this.member.current.ntsTrainingCompletedAt ? 'right' : 'center' }}><Button color="primary" onClick={() => window.open('https://convention.letip.com/','_blank','noopener,noreferrer')}>Convention Registration</Button></li>
            </>
            : null
        } */}
        <li>
          <Button color="primary" onClick={() => this.showInviteGuestModal = true}><i className="fa fa-user" /> Invite A Guest</Button>
        </li>
        <li>
          <LinkContainer to={{ pathname: route(Routes.member.tips, { tab: TIPS_VIEW_TABS.sendTip }) }}>
            <Button color="primary"><i className="fa fa-money" /> Pass A New Tip</Button>
          </LinkContainer>
        </li>
      </ul>
    </>
  }

  private scheduleNtsTraining = () => {
    this.renderScheduleNtsEventModal = true
  }

  private scheduleBoardTraining = () => {
    this.renderScheduleBoardTrainingEventModal = true
  }

  private renderDashboard = () => {
    return <>
      <LazyResourcePanel resource={this.member} initialLoader={<BarLoader width={100} widthUnit="%" loading={true} color="#12497d" />}>
        {member => <>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12">
              <TipsWidget member={member} type="Passed" />
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <TipsWidget member={member} type="Received" />
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <SponsorWidget member={member} />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-sm-6 col-12">
              <ProfileWidget member={member} />
              <VideoWidget member={member} />
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <BusinessWidget member={member} />
              <div className="card">
                <div className="card-body">
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div>
                      {
                        member.bankCode
                          ? <BankCode bankCode={member.bankCode} documentUrl={member.bankCodeDocumentUrl!} />
                          : <BankCodeLink url={member.crackMyCodeLandingUrl} />
                      }
                    </div>
                    {
                      !Config.HIDE_EBOOK_LINK
                        ? <div style={{ paddingTop: 10, width: 210, textAlign: 'right' }}>
                          {
                            member.bankCode
                              ? <BankEbookLink url={member.codebreakerEbookUrl} imageStyle={{ width: 200 }} textStyle={{ fontSize: 12 }} />
                              : null
                          }
                        </div>
                        : null
                    }
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6 col-12">
              <div>
                <ChapterWidget member={member} />
              </div>
              <div>
                {
                  member.chapter
                    ? <SupportAreaWidget chapterId={member.chapter!.id} />
                    : null
                }
              </div>
            </div>
          </div>
        </>}
      </LazyResourcePanel>
      {
        this.renderScheduleNtsEventModal && this.member.current
          ? <ScheduleNtsTrainingModal onClosed={() => this.renderScheduleNtsEventModal = false} memberId={this.member.current.id} />
          : null
      }

      {
        this.renderScheduleBoardTrainingEventModal && this.member.current
          ? <ScheduleBoardTrainingModal onClosed={() => this.renderScheduleBoardTrainingEventModal = false} memberId={this.member.current.id} />
          : null
      }

    </>

    // TODO: member calendar
  }

  render(): React.ReactNode {
    return <>
      {this.renderDashboard()}
      <InviteGuestModal
        isOpen={this.showInviteGuestModal}
        toggle={() => this.showInviteGuestModal = !this.showInviteGuestModal}
        onCancel={() => this.showInviteGuestModal = false}
        onSaved={() => {
          this.showInviteGuestModal = false
        }}
      />
    </>
  }
}

@observer
class BusinessWidget extends React.Component<{ member: Member }> {
  private renderSocialMediaLinks(social: {}) {
    const sites = {
      'facebook': { name: 'Facebook', icon: 'fa fa-facebook-square' },
      'twitter': { name: 'Twitter', icon: 'fa fa-twitter-square' },
      'linkedin': { name: 'Linkedin', icon: 'fa fa-linkedin-square' },
      'instagram': { name: 'Instagram', icon: 'fa fa-instagram' },
      'youtube': { name: 'YouTube', icon: 'fa fa-youtube' },
      'alignable': { name: 'Alignable', icon: 'fa fa-home' },
      'pinterest': { name: 'Pinterest', icon: 'fa fa-pinterest' },
      'snapchat': { name: 'Snapchat', icon: 'fa fa-snapchat' },
      'vimeo': { name: 'Vimeo', icon: 'fa fa-vimeo' },
    }

    const links = [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'alignable',
      'pinterest',
      'snapchat',
      'vimeo',
    ].filter(soc => !!social[soc])

    return links.length
      ? links.map(soc => <div key={soc} className="social-link-list-item">
        <a href={normalizeUrl(social[soc])} target="_blank"><i className={sites[soc].icon} /> {sites[soc].name}</a>
      </div>)
      : <p>Social media links have not been entered</p>
  }

  private hasSocialMediaLinks(social: {}) {
    return [
      'facebook',
      'twitter',
      'linkedin',
      'instagram',
      'youtube',
      'alignable',
      'pinterest',
      'snapchat',
      'vimeo',
    ].filter(soc => !!social[soc]).length > 0
  }

  render(): React.ReactNode {
    const member = this.props.member

    return <div className="card">
      <div className="card-body">
        <h6 className="text-muted">My Business</h6>
        {member.business && <>
          <div className="mb-2"><b>{member.business.name}</b></div>
          {
            member.business.logoUrl
              ? <div className="my-4"><img src={member.business.getLogoImageUrl()} width={130} height={130} style={{ backgroundColor: '#ccc' }} /></div>
              : null
          }
          {member.business.address && <div className="mb-2"><AddressView address={member.business.address} showMapLink={false} /></div>}
          {member.business.phoneNumber && <div>
            {member.business.phoneNumber}
          </div>}
          {member.business.emailAddress && <div>
            <a href={`mailto:${member.business.emailAddress}`}>{member.business.emailAddress}</a>
          </div>}
          {member.business.websiteUrl && <div className="mt-2">
            <a href={normalizeUrl(member.business.websiteUrl)} target="_blank">{member.business.websiteUrl}</a>
          </div>}
          {member.business.address && <div><MapView address={member.business.address} width={500} height={300} /></div>}
        </>}

        {
          (member.business && member.business.description && _.trim(member.business.description).length)
            ? <>
              <h6 className="text-muted mt-4">About My Business</h6>
              <div className="form-row">
                <div className="col-12">
                  {nl2br(member.business.description)}
                </div>
              </div>
            </>
            : null
        }

        {
          (member.business && this.hasSocialMediaLinks(member.business.socialLinks))
            ? <>
              <h6 className="text-muted mt-4">Social Media Profiles5</h6>
              <div className="form-row">
                <div className="col-12">
                  {this.renderSocialMediaLinks(member.business.socialLinks)}
                </div>
              </div>
            </>
            : null
        }

        {
          (member.business && member.business.hoursOfOperation)
            ? <>
              <h6 className="text-muted mt-4">Hours of Operation</h6>
              {member.business.hoursOfOperation.map(({ day, open, close }) => <>
                <p key={day}><span className="text-muted">{day}</span><br />{open && close ? `${open} to ${close}` : 'Closed'}</p>
              </>
              )}
            </>
            : null
        }

      </div>
    </div>
  }
}

@observer
class VideoWidget extends React.Component<{ member: Member }> {
  @observable private showModal = false

  render(): React.ReactNode {
    return this.props.member.videoData
      ? <>
        <div className="card">
          <div className="card-body text-center">
            <div className="video-thumbnail" onClick={() => this.showModal = true}>
              {
                this.props.member.videoData.thumbnailUrl
                  ? <img
                    style={{ maxWidth: '100%' }}
                    src={this.props.member.videoData.thumbnailUrl} />
                  : null
              }
              <div className="video-thumbnail-play"><i className="fa fa-play" /></div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.showModal} size="lg" toggle={() => this.showModal = false}>
          <ModalHeader toggle={() => this.showModal = false}>
            {this.props.member.business ? this.props.member.business.name : 'Video Player'}
          </ModalHeader>
          <ModalBody>
            <VideoPlayer videoData={this.props.member.videoData!} />
          </ModalBody>
        </Modal>
      </>
      : null
  }
}

@observer
class ChapterWidget extends React.Component<{ member: Member }> {
  render(): React.ReactNode {
    const member = this.props.member

    return <div className="card">
      <div className="card-body">
        <h6 className="text-muted">My Chapter</h6>
        {member.chapter && <>
          <Link to={route(Routes.chapter.index, { id: member.chapter.id })}>{member.chapter.name}</Link>
        </>}
        {member.category && <div>
          <b>Category:</b> {member.category.name}
        </div>}
        {member.chapter && member.chapter.area && <div>
          <b>Area:</b> {member.chapter.area.name}
        </div>}
      </div>
    </div>
  }
}

@observer
class SponsorWidget extends React.Component<{ member: Member }> {
  render(): React.ReactNode {
    const member = this.props.member

    return <div className="card">
      <div className="card-body">
        <div className="d-flex">
          <div className="mr-3">
            <Link to={route(Routes.member.profile, { tab: MEMBER_PROFILE_TABS.network })} className="link-muted">
              <div className={classNames(['info-icon', 'bg-warning'])}>
                <div style={{ fontSize: 24, fontWeight: 'bold', color: 'white' }}>#</div>
              </div>
            </Link>
          </div>
          <div className="flex-fill pt-3">
            <h5><span className="text-warning">{member.numberOfSponsoredMembers}</span> Sponsored</h5>
            {member.badgeColor && <div>
              <i className="fa fa-id-card text-muted" /> You are a <b>{member.badgeColor} Badge</b> Holder
            </div>}
          </div>
        </div>
      </div>
    </div>
  }
}

@observer
class ProfileWidget extends React.Component<{ member: Member }> {
  @observable private activeMembership = createLazyResource<Membership>(() => {
    return ApiClient.query(`
membership {
  *
}
    `, {
      where: [{ memberId: this.props.member.id }]
    })
  }, response => new Membership(response.data.membership))

  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'md'
  @observable
  private editModalTitle = 'Edit'

  private eventBus = new EventBus()

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'md') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  render(): React.ReactNode {
    const member = this.props.member

    return <EventBusContext.Provider value={{ eventBus: this.eventBus }}>
      <div className="card">
        <div className="card-body">
          <h6 className="text-muted">My Profile</h6>
          <div className="d-flex flex-row">
            <div style={{ paddingRight: 20 }}>
              <img alt="Member Profile Image" src={member.getProfileImageUrl()} style={{
                width: 65,
                height: 65,
                objectFit: 'cover',
                objectPosition: 'top center',
                backgroundColor: '#ccc',
              }} />
            </div>
            <div className="flex-fill">
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <h5>{member.fullName}</h5>
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  {member.joinDate && <div>Member Since {member.joinDate!.format('MM/DD/YYYY')}</div>}
                  <div style={{ display: 'flex' }}>
                    <div style={{ flex: 1 }}>
                      {member.badgeColor && <div className="mb-1">
                        <i className="fa fa-id-card text-muted" /> <b>{member.badgeColor} Badge</b>
                      </div>}
                    </div>
                    <div>
                      {
                        member.ntsTrainingCompletedAt
                          ? <NtsBadgeView />
                          : null
                      }
                    </div>
                  </div>
                  {member.emailAddress && <div>
                    <i className="fa fa-envelope-o" /> <a href={`mailto:${member.emailAddress}`}>{member.emailAddress}</a>
                  </div>}
                  {member.phoneNumber && <div>
                    <i className="fa fa-phone" /> {member.phoneNumber}
                  </div>}
                </div>
              </div>
            </div>
          </div>
          <div className="card-footer-shadow">
            <LazyResourcePanel resource={this.activeMembership} emptyMessage="No Active Membership">
              {activeMembership => <>
                <div><b>Renewal Date:</b> {activeMembership.endsAt ? activeMembership.endsAt.format('MM/DD/YYYY') : '---'}</div>
                <div><b>Last Payment:</b> {activeMembership.lastRenewedAt ? activeMembership.lastRenewedAt.format('MM/DD/YYYY') : '---'}</div>
                {activeMembership.endsAt && <Link
                  to={{ pathname: route(Routes.member.billing) }}
                  className="btn btn-primary btn-sm"
                >
                  Renew Now
                </Link>
                }
              </>}
            </LazyResourcePanel>
          </div>
        </div>

        <Modal
          isOpen={this.showModal}
          size={this.editModalSize}
        >
          <ModalHeader toggle={this.closeEditModal}>
            {this.editModalTitle}
          </ModalHeader>
          <ModalBody>{this.editModalContent}</ModalBody>
        </Modal>
      </div>
    </EventBusContext.Provider>
  }
}

type TipStatData = {
  totalCount: number
  totalValue: number
}

@observer
class TipsWidget extends React.Component<{
  type: 'Passed' | 'Received',
  member: Member
}> {
  @observable private period = 'month'
  @observable private tipData = createLazyResource<TipStatData>(() => {
    return ApiClient.getInstance().get(route(ApiRoutes.tips.stats, {}, { type: `memberCount`, member: this.props.member.id, view: this.props.type === 'Passed' ? 'sent' : 'received', period: this.period }))
  }, response => modelToCamelCase(response.data.tip_data) as TipStatData)

  @observable private isOpen = false

  private periodNames = {
    month: 'This Month',
    lastMonth: 'Last Month',
    year: 'This Year',
    lastYear: 'Last Year',
    today: 'Today',
  }

  private toggle = () => {
    this.isOpen = !this.isOpen
  }

  render(): React.ReactNode {
    const { type } = this.props

    return <div className="card">
      <div className="card-body">
        <div className="d-flex">
          <div className="mr-3">
            <Link to={route(Routes.member.tips, { tab: type === 'Passed' ? TIPS_VIEW_TABS.sentTips : TIPS_VIEW_TABS.receivedTips })} className="link-muted">
              <div className={classNames(['info-icon', type === 'Passed' ? 'bg-primary' : 'bg-success'])}>
                <i className={classNames(['fa', type === 'Passed' ? 'fa-user' : 'fa-money'])} />
              </div>
            </Link>
          </div>
          <div className="flex-fill pt-3">
            <LazyResourcePanel resource={this.tipData}>
              {tipData => <>
                <h5><span className="text-primary">{tipData.totalCount}</span> {pluralize('Tip', tipData.totalCount)} {type}</h5>
                {tipData.totalValue && tipData.totalValue > 0 && <div><i className="fa fa-money text-success" /> ${formatNumber(tipData.totalValue, 2)}</div>}
              </>}
            </LazyResourcePanel>
          </div>
        </div>
        <div style={{
          position: 'absolute',
          top: 0,
          right: 0,
        }}>
          <ButtonDropdown isOpen={this.isOpen} toggle={this.toggle}>
            <DropdownToggle outline caret size="sm" color="secondary">{this.periodNames[this.period]}</DropdownToggle>
            <DropdownMenu>
              {_.map(this.periodNames, (p, k) => <DropdownItem key={k} onClick={() => {
                this.period = k
                this.tipData.invalidate().then(() => {
                })
              }}>{p}</DropdownItem>)}
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      </div>
    </div>
  }
}

@observer
class SupportAreaWidget extends React.Component<{
  chapterId: number
}> {
  @observable private chapterData = createLazyResource<Chapter>(() => {
    return ApiClient.query(
      `
  chapter {
    supportAreas {
      *

      supportAreaUsers {
        *
        user {
          *
          member {
            *
          }
        }
      }
    }
  }
  `,
      {
        where: [
          { id: this.props.chapterId },
        ]
      }
    )
  }, response => new Chapter(response.data.chapter))

  render(): React.ReactNode {
    return <div className="card">
      <LazyResourcePanel resource={this.chapterData}>
        {chapter => <>
          <div className="card-header">
            <h6>Area Support</h6>
          </div>
          <div className="card-body">
            {chapter.supportAreas.map(supportArea => <div key={supportArea.id}>
              <b>{supportArea.name}</b>
              {(supportArea.supportAreaUsers.current || []).map((supportAreaUser: SupportAreaUser) => <div key={supportAreaUser.id} className="mb-4">
                <div><b>{supportAreaUser.title}</b></div>
                {
                  supportAreaUser.user.member
                    ?
                    <div className="d-flex">
                      <div>
                        <Link to={route(Routes.network.member, { memberId: supportAreaUser.user.member.id })} className="link-muted">
                          <MemberProfileImage
                            size={45}
                            profileImageUrl={supportAreaUser.user.member.profileImageUrl}
                            memberName={supportAreaUser.user.member.fullName}
                          />
                        </Link>
                      </div>
                      <div className="flex-fill ml-2 mr-2">
                        <div><Link to={route(Routes.network.member, { memberId: supportAreaUser.user.member.id })}>{supportAreaUser.user.member.fullName}</Link></div>
                        {
                          supportAreaUser.user.member.emailAddress
                            ? <div><a href={`mailto:${supportAreaUser.user.member.emailAddress}`}>{supportAreaUser.user.member.emailAddress}</a></div>
                            : null
                        }
                        {
                          supportAreaUser.user.member.phoneNumber
                            ? <div>{supportAreaUser.user.member.phoneNumber}</div>
                            : null
                        }
                      </div>
                    </div>

                    : <div className="d-flex">
                      <div>
                        <MemberProfileImage
                          size={45}
                          profileImageUrl={undefined}
                          memberName={supportAreaUser.user.name}
                        />
                      </div>
                      <div className="flex-fill ml-2 mr-2">
                        <div>{supportAreaUser.user.name}</div>
                        {
                          supportAreaUser.user.email
                            ? <div><a href={`mailto:${supportAreaUser.user.email}`}>{supportAreaUser.user.email}</a></div>
                            : null
                        }
                      </div>
                    </div>
                }
              </div>)}
            </div>)}
          </div>
        </>}
      </LazyResourcePanel>
    </div>
  }
}
