import * as React from 'react'
import { SyntheticEvent } from 'react'
import BaseView from './BaseView'
import { Alert, Button } from 'reactstrap'
import { loadChapterOptions, loadGuestOptions } from '../api/AsyncHelpers'
import Util, { formatCurrency, modelToCamelCase, modelToSnakeCase } from '../common/Util'
import StaticCheckbox from '../components/StaticCheckbox'
import moment from 'moment-timezone'
import AddPaymentMethodModal from '../components/AddPaymentMethodModal'
import FormError from '../components/FormError'
import { observer } from 'mobx-react'
import { computed, observable } from 'mobx'
import FormState from '../common/FormState'
import ErrorBag from '../common/ErrorBag'
import FormHelper from '../forms/FormHelper'
import ApiClient, { ApiRoutes } from '../api/ApiClient'
import { route } from '../routes/routes'
import AppStateStore from '../stores/AppStateStore'
import SignatureCanvas from "react-signature-canvas"
import { BarLoader } from 'react-spinners'
import Config from '../common/Config'
import * as _ from 'lodash'
import { RouteComponentProps } from 'react-router'

type PaymentMethodOption = {
  id: number
  description: string
}

type ChapterTransferData = {
  chapterTransferFeeTotal: number
  membershipType: string
  renewalDate: string
  paymentMethods: PaymentMethodOption[]
  chapter: {
    id: number
    name: string
  }
  category: {
    id: number
    name: string
  }
  business: {
    name: string
    phone: string
    email: string
    websiteUrl: string
    address: string
    address2: string
    country: string
    state: string
    city: string
    zipCode: string
  },
  hasPendingTransfer: boolean
  isAutoRenewalEnabled: boolean
  isAccountCurrent: boolean
}

type Props = {
  match: {
    params: {
      memberId: number
    }
  }
} & RouteComponentProps

@observer
export default class RequestChapterTransferView extends BaseView<Props> {
  @observable private loading = false
  @observable private submitting = false
  @observable private showConfirmation = false
  @observable private error?: string

  @observable private chapterTransferData?: ChapterTransferData
  @observable newChapterName?: string

  @observable private renderAddPaymentMethodModal = false
  @observable private showAddPaymentMethodModal = false

  @computed
  private get paymentMethodOptions () {
    return this.chapterTransferData
      ? this.chapterTransferData.paymentMethods.map(pm => ({
        value: String(pm.id),
        text: pm.description,
      }))
      : []
  }

  @observable private formState = new FormState({
    newChapterId: undefined,
    transferReason: '',
    replacementGuestId: undefined,
    replacementGuestType: 'existing',
    replacementGuest: {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
      companyName: '',
    },
    business: {
      phone: '',
      email: '',
      websiteUrl: '',
      address: '',
      address2: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
    },
    paymentMethodId: undefined,
    confirmed: false,
    enableAutoRenewal: false,
  })
  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private applicationSignatureRef = React.createRef<ApplicationSignature>()

  renderContentHeader () {
    return <h1>Chapter Transfer Request</h1>
  }

  componentDidMount (): void {
    super.componentDidMount()

    this.loadChapterTransferData()
  }

  protected loadChapterTransferData = () => {
    this.loading = true

    ApiClient.getInstance()
      .get(route(ApiRoutes.members.getChapterTransferData, { id: this.props.match.params.memberId }))
      .then(response => {
        this.chapterTransferData = modelToCamelCase(response.data) as ChapterTransferData

        if (this.chapterTransferData.hasPendingTransfer) {
          this.error = 'This member already has a pending chapter transfer request'
        }

        if (!this.formState.get('paymentMethodId') && this.paymentMethodOptions.length) {
          this.formState.set('paymentMethodId', this.paymentMethodOptions[0].value)
        }

        this.formState.set('business.phone', this.chapterTransferData.business.phone || '')
        this.formState.set('business.email', this.chapterTransferData.business.email || '')
        this.formState.set('business.websiteUrl', this.chapterTransferData.business.websiteUrl || '')
        this.formState.set('business.address', this.chapterTransferData.business.address || '')
        this.formState.set('business.address2', this.chapterTransferData.business.address2 || '')
        this.formState.set('business.country', this.chapterTransferData.business.country || '')
        this.formState.set('business.state', this.chapterTransferData.business.state || '')
        this.formState.set('business.city', this.chapterTransferData.business.city || '')
        this.formState.set('business.zipCode', this.chapterTransferData.business.zipCode || '')
        if (!this.chapterTransferData.isAutoRenewalEnabled) {
          this.formState.set('enableAutoRenewal', true)
        }
      })
      .catch(err => {
        this.error = Util.extractErrorMessage(err.response)
      })
      .then(() => this.loading = false)
  }

  private scrollToFormErrors = (formErrors?: ErrorBag) => {
    setTimeout(() => {
      const el: HTMLCollectionOf<Element> = document.getElementsByClassName('form-error')

      if (el.length) {
        window.scroll({ left: 0, top: window.scrollY + el[0].getBoundingClientRect().top - 100, behavior: 'smooth' })
      } else if (formErrors && formErrors.hasErrors()) {
        // there are no visible errors on the form, but we have errors. show them in a modal
        AppStateStore.showAlertModal('Error', _.map(formErrors.getErrors(), (e, idx) => <div key={idx}>{e}</div>))
      }
    })
  }

  protected submit = (ev: SyntheticEvent) => {
    ev.preventDefault()

    this.submitting = true
    AppStateStore.showModalSpinner()
    this.formErrors.clearErrors()

    const applicationSignatureImageData = this.applicationSignatureRef.current!.toDataURL()

    const submitData = {
      ...this.formHelper.toObject(),
      applicationSignatureImageData,
      chapterTransferFeeTotal: this.chapterTransferData!.chapterTransferFeeTotal,
    }

    ApiClient.getInstance().post(route(ApiRoutes.members.requestChapterTransfer, { id: this.props.match.params.memberId }), modelToSnakeCase(submitData))
      .then(() => {
        this.showConfirmation = true
        window.scrollTo(0, 0)
      })
      .catch(error => {
        const errors = new ErrorBag()
        Util.handleErrorResponse(error.response, errors, undefined, (response, message) => {
          AppStateStore.showAlertModal('Error', message, m => {
            m.hide()
          })
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
        this.scrollToFormErrors(errors)
      })
      .then(() => {
        this.submitting = false
        AppStateStore.dismissModalSpinner()
      })
  }

  renderContentBody () {
    return this.error
      ? <Alert color="error">{this.error}</Alert>
      : this.showConfirmation
        ? this.renderConfirmation()
        : this.loading || !this.chapterTransferData
          ? <BarLoader width={100} widthUnit="%" loading={true} color="#12497d"/>
          : this.renderApplication()
  }

  private renderConfirmation = () => {
    return <div>
      <p>
        Your Chapter Transfer Application will be reviewed and approved by your Chapter Board.<br/><br/>
        Your chapter board have received an email letting them know of your request, but you should also reach out to your chapter president to explain the transfer.
      </p>
    </div>
  }

  private renderApplication = () => {
    return <>
      <Alert color="info">
        To request a chapter transfer, choose a new chapter below and your chapter transfer application will be submitted to your chapter board. Once approved, a membership application will be submitted to your new chapter. When your new chapter has approved your membership application, your payment
        method will be charged.
      </Alert>

      <form onSubmit={this.submit}>
        <div className="application-subheader">Transfer Application</div>
        <div className="form-row">
          <div className="col-12" style={{ zIndex: 2000 }}>
            {this.formHelper.renderAsyncSelectInput({
              label: 'Which chapter do you want to transfer to?',
              name: 'newChapterId',
              loadOptions: loadChapterOptions,
              onChange: option => this.newChapterName = option ? option.label : undefined,
            })}
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            {this.formHelper.renderTextAreaInput({
              label: 'What is the reason you wish to transfer?',
              name: 'transferReason',
            })}
          </div>
        </div>
        <hr/>
        <div className="form-row">
          <div className="col-12">
            <p>I am recommending the following guest for my category in the chapter</p>
            <div>
              {this.formHelper.renderSelectInput({
                label: '',
                name: 'replacementGuestType',
                options: [
                  { value: 'existing', text: 'A previously invited guest' },
                  { value: 'new', text: 'Invite a new guest' },
                  { value: 'none', text: 'I do not have a recommendation for my replacement in this chapter' },
                ]
              })}
            </div>
          </div>
        </div>
        {
          this.formState.get('replacementGuestType') != 'none'
            ? <div className="form-row">
              <div className="col-12">
                <div className="form-group">
                  <div><label>Replacement Guest Category</label></div>
                  <b>{this.chapterTransferData!.category.name}</b>
                </div>
              </div>
            </div>
            : null
        }
        {
          this.formState.get('replacementGuestType') === 'existing'
            ? <div className="form-row">
              <div className="col-12" style={{ zIndex: 1999 }}>
                {this.formHelper.renderAsyncSelectInput({
                  label: 'Search for a previously invited guest',
                  name: 'replacementGuestId',
                  loadOptions: loadGuestOptions(this.chapterTransferData!.chapter.id, this.chapterTransferData!.category.id),
                  minimumSearchCharacters: 2,
                })}
              </div>
            </div>
            : null
        }
        {
          this.formState.get('replacementGuestType') === 'new'
            ? <div className="form-row">
              <div className="col-12">
                <ReplacementGuestForm formHelper={this.formHelper}/>
              </div>
            </div>
            : null
        }
        <hr/>
        <div className="form-row">
          <div className="col-12">
            <ul>
              <li>Your current membership is <StaticCheckbox checked={this.chapterTransferData!.membershipType === 'Personal'}/> Personally Owned <StaticCheckbox checked={this.chapterTransferData!.membershipType === 'Company'}/> Company Owned</li>
              <li>Your next LeTip Renewal is due <span style={{ paddingLeft: 30, paddingRight: 30, borderBottom: 'solid 1px #000' }}>{this.chapterTransferData!.renewalDate ? moment(this.chapterTransferData!.renewalDate).format('MM/DD/YYYY') : 'N/A'}</span></li>
              <li>
                {
                  this.chapterTransferData!.isAccountCurrent
                    ? 'Your account is current with LeTip International'
                    : <>
                      <b>Your account is NOT current with LeTip International. You can continue, but will not be able to be approved until you are current.</b>
                    </>
                }
              </li>
              <li>I affirm that I will pay the {formatCurrency(this.chapterTransferData!.chapterTransferFeeTotal)} Transfer Fee to LeTip International</li>
              {
                this.newChapterName
                  ? <li>I affirm that I will pay any incoming Chapter Fees to {this.newChapterName ? this.newChapterName : ''}</li>
                  : null
              }
            </ul>
          </div>
        </div>
        <div className="form-row">
          <div className="col-12">
            <div className="application-checkbox-container">
              {
                this.formHelper.renderCheckboxInput({
                  label: `By checking this box you are confirming that you have read and verified that the above information is correct`,
                  name: 'confirmed',
                  noMargin: true,
                })
              }
            </div>
          </div>
        </div>
        <div className="callout-box text-right mb-4 mt-2">
          Chapter Transfer Fee: {formatCurrency(this.chapterTransferData!.chapterTransferFeeTotal)} USD
        </div>
        <div className="form-row">
          <div className="col-12">
            <label>Payment Method</label>
            {this.formHelper.renderSelectInput({
              name: 'paymentMethodId',
              options: this.paymentMethodOptions,
              append: <Button
                type="button"
                color="primary"
                onClick={() => {
                  this.showAddPaymentMethodModal = true
                  this.renderAddPaymentMethodModal = true
                }}>Add Payment Method</Button>,
              appendContent: true,
            })}
          </div>
        </div>
        {
          !this.chapterTransferData!.isAutoRenewalEnabled
            ? <div className="form-row">
              <div className="col-12">
                {
                  this.formHelper.renderCheckboxInput({
                    label: 'Enable automatic renewals for my LeTip membership',
                    name: 'enableAutoRenewal',
                  })
                }
                {
                  this.formState.get('enableAutoRenewal')
                    ? <p>
                      I agree to have my LeTip membership automatically renew at the current rate.
                      My membership will renew annually on the month that my membership application is approved by LeTip International, Inc.
                      I acknowledge that LeTip International, Inc. reserves the right to increase membership dues; I understand that I will be given not less than 30 day notice of any such increase.
                      <b>If I cancel the automatic renewal option for my membership, I will enter my request into LeTip Wired&trade; or I must send a written notice, not less than 30 days, before cancellation to: LeTip International, Inc., 7895 West Sunset Road, Suite 101, Las Vegas, NV 89113.</b>
                    </p>
                    : null
                }
              </div>
            </div>
            : null
        }
      </form>
      {
        this.renderAddPaymentMethodModal
          ? <AddPaymentMethodModal
            isOpen={this.showAddPaymentMethodModal}
            toggle={() => this.showAddPaymentMethodModal = false}
            onClosed={() => this.renderAddPaymentMethodModal = false}
            onSaved={paymentMethod => {
              this.chapterTransferData!.paymentMethods.push({
                id: paymentMethod.id,
                description: paymentMethod.description,
              })
              this.formState.set('paymentMethodId', String(paymentMethod.id))
              this.showAddPaymentMethodModal = false
            }}
            memberId={this.props.match.params.memberId}
          />
          : null
      }

      <div className="application-subheader" style={{ marginTop: 40 }}>Business Information</div>
      <p>
        Please verify your business information below and make any corrections necessary. This will be the information used on your LeTip Membership Application that is sent to your new chapter.
      </p>
      <BusinessInformationForm
        formHelper={this.formHelper}
        chapterTransferData={this.chapterTransferData!}
      />

      <div className="application-subheader">Application Signature</div>
      <p>
        My signature below attests that I understand that <b>LeTip International dues are non-refundable</b>,
        and that I have read, understand, and agree to abide by LeTip International's Application Agreement,
        fee structure and program requirements. I also understand that if I resign from LeTip, or my membership
        is terminated by the board of directors or by a LeTip International representative, <b>my membership
        and renewal dues are non-refundable.</b>
      </p>

      <div>
        <ApplicationSignature
          ref={this.applicationSignatureRef}
        />
        <FormError errors={this.formErrors} fieldName="applicationSignatureImageData"/>
      </div>

      <div className="form-buttons">
        <Button
          color="success"
          onClick={this.submit}
        >Submit Application</Button>
      </div>
    </>
  }
}

@observer
class ApplicationSignature extends React.Component {
  private canvasRef = React.createRef<SignatureCanvas>()

  toDataURL (): string {
    return (this.canvasRef.current as any).toDataURL("image/png")
  }

  render (): React.ReactNode {
    return <div className="signature-container">
      <div className="signature-box">
        <SignatureCanvas
          canvasProps={{ className: 'signature-canvas' }}
          ref={this.canvasRef}
        />
        <label>Sign with your mouse</label>
      </div>
      <div className="clear-signature">
        <Button
          color="secondary"
          onClick={() => (this.canvasRef.current as any)!.clear()}
        >Clear Signature</Button>
      </div>
      <div className="signature-date">
        <span>{moment().format('MMMM DD, YYYY')}</span>
      </div>
      <div className="label-tab">
        Sign Here
        <div className="label-tab-arrow"/>
      </div>
    </div>
  }
}

@observer
class BusinessInformationForm extends React.Component
  <{
    formHelper: FormHelper<any>,
    chapterTransferData: ChapterTransferData,
  }> {

  render (): React.ReactNode {
    const formHelper = this.props.formHelper
    return <>
      <h6>Contact Information</h6>

      <div className="form-row">
        <div className="col-sm-6">
          <div><label>Business Name</label></div>
          <div><b>{this.props.chapterTransferData.business.name}</b></div>
        </div>
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            type: 'text',
            name: 'business.phone',
            label: 'Primary Phone',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'business.email',
            type: 'email',
            label: 'Primary Email Address',
          })}
        </div>
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'business.websiteUrl',
            type: 'text',
            label: 'Business Website',
          })}
        </div>
      </div>

      <h6>Address Information</h6>

      <div className="form-row">
        <div className="col-sm-8">
          {this.props.formHelper.renderTextInput({
            name: 'business.address',
            type: 'text',
            label: 'Street',
          })}
        </div>
        <div className="col-sm-4">
          {this.props.formHelper.renderTextInput({
            name: 'business.address2',
            type: 'text',
            label: 'Suite / Room / Apt #',
          })}
        </div>
      </div>

      <div className="form-row">
        <div className="col-sm-3">
          {this.props.formHelper.renderTextInput({
            name: 'business.city',
            type: 'text',
            label: 'City',
          })}
        </div>
        <div className="col-sm-3">
          {this.props.formHelper.renderTextInput({
            name: 'business.state',
            type: 'text',
            label: 'State',
          })}
        </div>
        <div className="col-sm-3">
          {this.props.formHelper.renderTextInput({
            name: 'business.zipCode',
            type: 'text',
            label: 'Zip Code',
          })}
        </div>
        <div className="col-sm-3">
          {this.props.formHelper.renderSelectInput({
            name: 'business.country',
            options: Config.COUNTRY_OPTIONS.map(c => ({ value: c, text: c })),
            label: 'Country',
          })}
        </div>
      </div>
    </>
  }
}

@observer
class ReplacementGuestForm extends React.Component
  <{
    formHelper: FormHelper<any>,
  }> {

  render (): React.ReactNode {
    const formHelper = this.props.formHelper
    return <>
      <h6>Replacement Guest Information</h6>

      <div className="form-row">
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'replacementGuest.firstName',
            label: 'Guest First Name',
          })}
        </div>
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'replacementGuest.lastName',
            label: 'Guest Last Name',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'replacementGuest.phone',
            label: 'Guest Phone Number',
            type: 'tel',
          })}
        </div>
        <div className="col-sm-6">
          {formHelper.renderTextInput({
            name: 'replacementGuest.email',
            label: 'Guest Email Address',
            type: 'email',
          })}
        </div>
      </div>
      <div className="form-row">
        <div className="col-sm-12">
          {formHelper.renderTextInput({
            name: 'replacementGuest.companyName',
            label: 'Guest Company Name',
            type: 'text',
          })}
        </div>
      </div>
    </>
  }
}
