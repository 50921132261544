import * as React from "react"
import { SyntheticEvent } from "react"
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import Util, { formatCurrency, modelToCamelCase, transformIf } from "../common/Util"
import { observer } from "mobx-react"
import { observable } from "mobx"
import StartMembershipForm from "./StartMembershipForm"
import AppStateStore from "../stores/AppStateStore"
import ApiClient, { ApiRoutes } from "../api/ApiClient"
import { toast } from "react-toastify"
import ActiveMembershipForm from "./ActiveMembershipForm"
import RenewMembershipForm from "./RenewMembershipForm"
import LazyResourceView from "./LazyResourceView"
import RequirePermission, { Permission } from "./RequirePermission"
import Membership from "../models/Membership"
import EventBus, { EventBusContext } from "../common/EventBus"
import LazyResource from "../models/LazyResource"
import LazyResourcePanel from "./LazyResourcePanel"
import MembershipFees from "../models/MembershipFees"
import MembershipRenewal from "../models/MembershipRenewal"
import { InvoiceDetailModal } from "./InvoiceDetailModal"
import RequireRole from "./RequireRole"
import { UserRole } from "../models/AuthUser"
import { route } from "../routes/routes"
import HelpTooltip from './HelpTooltip'
import TerminateMembershipModal from './system/members/TerminateMembershipModal'

type Props = {
  memberId: number
}

@observer
export default class ActiveMembership extends React.Component<Props> {
  static contextType = EventBusContext
  context!: React.ContextType<typeof EventBusContext>

  @observable
  private showModal = false
  @observable
  private editModalContent?: React.ReactNode
  @observable
  private editModalSize = 'md'
  @observable
  private editModalTitle = 'Edit'
  @observable private renderTerminateMembershipModal = false

  @observable private loaded = false
  @observable private loading = false
  @observable private error?: string

  @observable private activeMembership = new LazyResource<Membership | undefined>((callback, error) => {
    ApiClient.query(`
    member {
      *

      activeMembership {
        *
      }
    }
    `, {
      where: [{ id: this.props.memberId }],
    })
      .then(response => callback(response.data.member.activeMembership ? new Membership().init(response.data.member.activeMembership) : undefined))
      .catch(err => error(Util.extractErrorMessage(err.response)))
  })

  @observable private membershipFees = new LazyResource<MembershipFees>((callback, error) => {
    ApiClient.members.getMembershipFees(this.props.memberId)
      .then(response => callback(new MembershipFees(modelToCamelCase(response.data.fees))))
      .catch(() => error('There was an error loading the membership fees'))
  })

  @observable private pendingMembershipRenewal = new LazyResource<MembershipRenewal | undefined>((callback, error) => {
    ApiClient.query(`
    membershipRenewal {
      *

      invoice {
        *
      }
    }
    `, {
      where: [
        { _scope: 'unpaid' },
        { _scope: 'member', value: this.props.memberId },
      ],
    })
      .then(response => callback(response.data.membershipRenewal ? new MembershipRenewal().init(response.data.membershipRenewal) : undefined))
      .catch(err => error(Util.extractErrorMessage(err.response), err.response.status))
  })

  @observable private invoiceDetailId?: number
  @observable private showInvoiceDetail = false

  componentDidMount (): void {
    this.addListeners(this.context.eventBus)
  }

  componentWillUnmount (): void {
    this.removeListeners(this.context.eventBus)
  }

  private addListeners = (eventBus: EventBus) => {
    eventBus.on('member-invalidated', this.onMemberInvalidated)
    eventBus.on('invoice-invalidated', this.onMemberInvalidated)
  }

  private removeListeners = (eventBus: EventBus) => {
    eventBus.remove(this.onMemberInvalidated)
  }

  private onMemberInvalidated = () => {
    this.activeMembership.invalidate()
    this.membershipFees.invalidate()
    this.pendingMembershipRenewal.invalidate()
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.memberId !== this.props.memberId) {
      this.activeMembership.invalidate()
    }
  }

  private showEditModal = (title: string, component: React.ReactNode, size: string = 'md') => {
    this.editModalTitle = title
    this.editModalContent = component
    this.showModal = true
    this.editModalSize = size
  }

  private closeEditModal = () => {
    this.showModal = false
    this.editModalContent = undefined
  }

  private enableAutoRenew = () => {
    AppStateStore.showConfirmationModal('Enable Auto Renew', <>
      <p className="text-center">I agree to have my LeTip membership automatically renew at the current rate. My membership will renew annually on the month that my membership application is approved by LeTip International, Inc. I acknowledge that LeTip International, Inc. reserves the right to
        increase membership dues; I understand that I will be given not less than 30 day notice of any such increase. <b>If I cancel the automatic renewal option for my membership, I will enter my request into LeTip Wired&trade; or I must send a written notice, not less than 30 days, before
          cancellation to: LeTip International, Inc., 7895 West Sunset Road, Suite 101, Las Vegas, NV 89113.</b></p>
    </>, (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.members.setMembershipAutoRenewal(this.props.memberId, this.activeMembership.current!.id, true)
          .then(() => {
            toast.success('Auto renewal has been enabled')
            this.context.eventBus.dispatch('member-invalidated')
          }, error => {
            Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            })
          })
          .then(() => {
            AppStateStore.dismissModalSpinner()
          })
      }

      modal.hide()
    })
  }

  private disableAutoRenew = () => {
    AppStateStore.showConfirmationModal('Disable Auto Renew', 'Are you sure you want to turn off auto renewal of your membership?', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.members.setMembershipAutoRenewal(this.props.memberId, this.activeMembership.current!.id, false)
          .then(() => {
            toast.success('Auto renewal has been disabled')
            this.context.eventBus.dispatch('member-invalidated')
          }, error => {
            Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
              AppStateStore.showAlertModal('Error', message, m => {
                m.hide()
              })
              return true
            })
          })
          .then(() => {
            AppStateStore.dismissModalSpinner()
          })
      }

      modal.hide()
    })
  }

  private terminateMembership = () => {
    this.renderTerminateMembershipModal = true
  }

  private generateRenewalInvoice = () => {
    AppStateStore.showConfirmationModal('Generate Renewal Invoice', 'Are you sure you want to generate a renewal invoice for this member?', (result, modal) => {
      if (result) {
        AppStateStore.showModalSpinner()

        ApiClient.getInstance()
          .post(route(ApiRoutes.members.generateRenewalInvoice, { id: this.props.memberId }))
          .then(() => {
            toast.success('Renewal invoice has been generated')
            this.context.eventBus.dispatch('member-invalidated')
          })
          .catch(error => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(error.response)))
          .then(() => {
            AppStateStore.dismissModalSpinner()
          })
      }

      modal.hide()
    })
  }

  private renderCreateMembership = () => {
    return <>
      <p>This member does not have an active membership</p>
      <RequirePermission permission={Permission.CreateMembership} context={{ memberId: this.props.memberId }}>
        {() =>
          <Button
            type="button"
            color="primary"
            onClick={() => {
              this.showEditModal('Start Membership', <StartMembershipForm
                memberId={this.props.memberId}
                onSaved={() => {
                  this.closeEditModal()
                }}
                onCancel={() => {
                  this.closeEditModal()
                }}
              />)
            }}
          >Start Membership</Button>
        }
      </RequirePermission>
    </>
  }

  render (): React.ReactNode {
    return <>
      <h5 className="text-muted">
        LeTip Membership
        <LazyResourceView resource={this.activeMembership}>
          <RequirePermission permission={Permission.EditMembership} context={{ memberId: this.props.memberId }}>
            {() => <>
              &nbsp;<a href="" onClick={ev => {
              ev.preventDefault()
              this.showEditModal('Active Membership',
                <ActiveMembershipForm
                  memberId={this.props.memberId}
                  onSaved={() => this.closeEditModal()}
                  onCancel={() => this.closeEditModal()}
                />, 'sm')
            }}><i className="fa fa-pencil"/></a>
            </>}
          </RequirePermission>
        </LazyResourceView>
      </h5>
      <LazyResourcePanel resource={this.activeMembership} emptyMessage={this.renderCreateMembership()}>
        {activeMembership => <>
          <div className="form-row">
            <div className="col-lg-3 col-md-6">
              <b>Active Since</b><br/>
              {activeMembership!.startsAt.format('MM/DD/YYYY')}
            </div>
            <div className="col-lg-3 col-md-6">
              <b>Renews On</b><br/>
              {transformIf(activeMembership!.endsAt, d => d.format('MM/DD/YYYY')) || 'Never'}
            </div>
            <div className="col-lg-3 col-md-6">
              <b>Renewal Method</b><br/>
              <div>{activeMembership!.isAutoRenewal ? 'Automatic' : 'Manual'}</div>
              <RequirePermission permission={Permission.ChangeMembershipAutoRenew} context={{ memberId: this.props.memberId }}>
                {() => <div style={{ display: 'flex' }}>
                  <Button type="button" color={activeMembership!.isAutoRenewal ? 'danger' : 'success'} size="sm" onClick={activeMembership!.isAutoRenewal ? this.disableAutoRenew : this.enableAutoRenew}>{activeMembership!.isAutoRenewal ? 'Disable' : 'Enable'} Auto Renewal</Button>
                  {
                    !activeMembership!.isAutoRenewal
                      ? <div style={{ marginLeft: 4 }}><HelpTooltip
                        content="Click on green button to enable auto renew. Click on red button to disable auto renew. Once auto renew is enabled, your account will be charged on the 1st day of the month of your renewal month."
                      /></div>
                      : null
                  }
                </div>}
              </RequirePermission>
            </div>
            <div className="col-lg-3 col-md-6">
              <LazyResourcePanel resource={this.pendingMembershipRenewal} emptyMessage={<>
                <b>Renewal Amount</b><br/>
                <LazyResourceView resource={this.membershipFees}>
                  {(membershipFees) => transformIf(membershipFees, c => c && formatCurrency(c.getCurrentRenewalFee()) || '---')}
                </LazyResourceView>
                <Button
                  type="button"
                  color="primary"
                  block
                  disabled={!activeMembership!.endsAt}
                  size="sm" onClick={(ev: SyntheticEvent) => {
                  ev.preventDefault()
                  this.showEditModal('Renew Membership',
                    <RenewMembershipForm
                      memberId={this.props.memberId}
                      onSaved={() => this.closeEditModal()}
                      onCancel={() => this.closeEditModal()}
                    />, 'lg')
                }}>Renew Now</Button>
                <RequireRole role={UserRole.Admin}>
                  {() => <>
                    <Button
                      type="button"
                      color="info"
                      block
                      size="sm"
                      onClick={() => {
                        this.generateRenewalInvoice()
                      }}
                    >Generate Invoice</Button>
                    <Button
                      onClick={this.terminateMembership}
                      type="button"
                      color="danger"
                      block
                      size="sm">Terminate</Button>
                  </>}
                </RequireRole>
              </>}>
                {membershipRenewal => <>
                  <b>Renewal Amount</b><br/>
                  <div>{formatCurrency(membershipRenewal.invoice.amountDue)}</div>
                  <a className="btn btn-primary" href={membershipRenewal.invoice.payInvoiceUrl} target="_blank">Pay Invoice</a>
                  <RequireRole role={UserRole.Admin}>
                    {() => <>
                      &nbsp;
                      <Button
                        type="button"
                        color="danger"
                        size="sm" onClick={(ev: SyntheticEvent) => {
                        ev.preventDefault()

                        AppStateStore.showConfirmationModal('Cancel Renewal', 'Are you sure you want to cancel this renewal and delete the renewal invoice?', (result, modal) => {
                          if (result) {
                            AppStateStore.showModalSpinner()

                            ApiClient.getInstance().post(route(ApiRoutes.membershipRenewals.cancelRenewal, { membershipRenewal: membershipRenewal.id }))
                              .then(() => {
                                this.context.eventBus.dispatch('member-invalidated')
                              })
                              .catch(err => AppStateStore.showAlertModal('Error', Util.extractErrorMessage(err.response)))
                              .then(() => AppStateStore.dismissModalSpinner())
                          }

                          modal.hide()
                        })
                      }}>Cancel Renewal</Button>
                    </>
                    }</RequireRole>
                </>}
              </LazyResourcePanel>
            </div>
          </div>
        </>}
      </LazyResourcePanel>

      {
        this.invoiceDetailId
          ? <InvoiceDetailModal
            isOpen={this.showInvoiceDetail}
            toggle={() => this.showInvoiceDetail = false}
            invoiceId={this.invoiceDetailId}
            onClosed={() => this.invoiceDetailId = undefined}
          />
          : null
      }

      <Modal
        isOpen={this.showModal}
        size={this.editModalSize}
      >
        <ModalHeader toggle={this.closeEditModal}>
          {this.editModalTitle}
        </ModalHeader>
        <ModalBody>{this.editModalContent}</ModalBody>
      </Modal>

      {
        this.renderTerminateMembershipModal
          ? <TerminateMembershipModal onClosed={() => {
            this.renderTerminateMembershipModal = false
            this.context.eventBus.dispatch('member-invalidated')
          }} memberId={this.props.memberId}/>
          : null
      }
    </>
  }
}
