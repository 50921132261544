import Chapter from "../models/Chapter";
import React, { Component, useEffect, useRef, useState } from 'react';
import ApiClient, { ApiRoutes } from "../api/ApiClient";
import { route } from "../routes/routes";
import { toast } from "react-toastify";
import Util from "../common/Util";
import AppStateStore from "../stores/AppStateStore";

type Props = {
    chapter: Chapter,
}

const ChapterPhotosView: React.FC<Props> = ({ chapter }) => {
    const [file, setFile] = useState<File | null>(null);
    const [file1, setFile1] = useState<File | null>(null);
    const [file2, setFile2] = useState<File | null>(null);
    const [file3, setFile3] = useState<File | null>(null);
    const [file4, setFile4] = useState<File | null>(null);
    const [coverexists, setCoverexists] = useState<Boolean>(false);
    const [coverexists1, setCoverexists1] = useState<Boolean>(false);
    const [coverexists2, setCoverexists2] = useState<Boolean>(false);
    const [coverexists3, setCoverexists3] = useState<Boolean>(false);
    const [coverexists4, setCoverexists4] = useState<Boolean>(false);
    const [imagePath, setImagePath] = useState<string>('');
    const [imagePath1, setImagePath1] = useState<string>('');
    const [imagePath2, setImagePath2] = useState<string>('');
    const [imagePath3, setImagePath3] = useState<string>('');
    const [imagePath4, setImagePath4] = useState<string>('');

    const fileInputRef = useRef<HTMLInputElement | null>(null);
    const fileInputRef1 = useRef<HTMLInputElement | null>(null);
    const fileInputRef2 = useRef<HTMLInputElement | null>(null);
    const fileInputRef3 = useRef<HTMLInputElement | null>(null);
    const fileInputRef4 = useRef<HTMLInputElement | null>(null);

    const [error, setError] = useState<string>('');
    const [error1, setError1] = useState<string>('');
    const [error2, setError2] = useState<string>('');
    const [error3, setError3] = useState<string>('');
    const [error4, setError4] = useState<string>('');

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        setFile(selectedFile || null);
    };

    const handleFileChange1 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        setFile1(selectedFile || null);
    };
    const handleFileChange2 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        setFile2(selectedFile || null);
    };
    const handleFileChange3 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        setFile3(selectedFile || null);
    };
    const handleFileChange4 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files && e.target.files[0];
        setFile4(selectedFile || null);
    };

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            AppStateStore.showModalSpinner();
            return ApiClient.getInstance().get(route(ApiRoutes.chapters.fetchChapterCoverPhoto, { id: chapter.id }))
                .then(
                    response => {
                        if (response.status >= 200 && response.status < 300 && response['data'] != null) {
                            if (response['data']['cover_photo'] != null && response['data']['cover_photo'] != "") {
                                setImagePath(response['data']['cover_photo']);
                                setCoverexists(true);
                            }
                            if (response['data']['main_photos'] != null && response['data']['main_photos'] != "") {
                                const main_photos = response['data']['main_photos'];
                                if (main_photos.hasOwnProperty("1")) {
                                    setImagePath1(main_photos["1"]);
                                    setCoverexists1(true);
                                }
                                if (main_photos.hasOwnProperty("2")) {
                                    setImagePath2(main_photos["2"]);
                                    setCoverexists2(true);
                                }
                                if (main_photos.hasOwnProperty("3")) {
                                    setImagePath3(main_photos["3"]);
                                    setCoverexists3(true);
                                }
                                if (main_photos.hasOwnProperty("4")) {
                                    setImagePath4(main_photos["4"]);
                                    setCoverexists4(true);
                                }
                            }
                            AppStateStore.dismissModalSpinner();
                            if (response['data']['cover_photo'] == null) {
                                setCoverexists(false);
                            }
                        }
                        else {
                            AppStateStore.dismissModalSpinner();
                        }
                    },
                    error => {
                        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
                            AppStateStore.showAlertModal("Error", message, m => {
                                m.hide();
                            });
                            setCoverexists(false)
                            AppStateStore.dismissModalSpinner();
                            return true;
                        });
                    }
                )
        } catch (error) {
            console.error('Error:', error);
            toast.error("Failed to load Cover photo, Please reload the page");
            return true;
        }
    }

    const handleFileUpload = async () => {
        AppStateStore.showModalSpinner();
        try {
            if (!file) {
                console.error('No file selected');
                return;
            }

            else if(file.size > 2048000 ){
                setError('File size exceeds the limit of 2MB.');
                AppStateStore.dismissModalSpinner();
                return;
            }

            const formData = new FormData();
            formData.append('file', file);
            formData.append('id', chapter.id.toString());
            // const response = await axios.post(route(ApiRoutes.chapters.updateChapterCoverPhoto, {'id':chapter.id,'file':file}), {
            // });
            return ApiClient.
                // getInstance().post(route(ApiRoutes.chapters.updateChapterCoverPhoto, { id: chapter.id, file: file}))
                getInstance().post(route(ApiRoutes.chapters.updateChapterCoverPhoto, { id: chapter.id }), formData)
                .then(
                    response => {
                        if (response.status >= 200 && response.status < 300) {
                            AppStateStore.dismissModalSpinner();
                            toast.success("Chapter Cover Photo Updated");
                            setFile(null);
                            fetchData();
                        }
                        else {
                            fetchData();
                            AppStateStore.dismissModalSpinner();
                            toast.error("Failed to update Cover photo");
                        }
                    },
                    error => {
                        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
                            AppStateStore.showAlertModal("Error", message, m => {
                                m.hide();
                            });
                            AppStateStore.dismissModalSpinner();
                            return true;
                        });
                    }
                )
        } catch (error) {
        }
    };

    const handleImageClick = async (e: React.MouseEvent<HTMLDivElement>) => {
        setError('');
        // Check if the click event originated from the designated area
        const isClickInDesignatedArea = e.target === e.currentTarget;
        if (isClickInDesignatedArea) {
            const fileInput = fileInputRef.current;
            if (fileInput) {
                // Keep track of whether the file input dialog is opened
                let isFileDialogOpened = false;
                const currentFile = file;

                // Define the input event handler
                const handleInput = () => {
                    if (!fileInput.files || fileInput.files.length === 0) {
                        // Restore the previous file if the selection was canceled
                        setCoverexists(true);
                        setFile(currentFile);

                        // User canceled the file selection dialog without selecting a file
                        console.log("User canceled the file selection dialog without selecting a file.");
                    } else {
                        // User selected file(s)
                        setCoverexists(false);
                        console.log("User selected file(s):", fileInput.files);
                    }

                    // Reset the flag for the file dialog state
                    isFileDialogOpened = false;

                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                };

                // Add event listener for the input event
                fileInput.addEventListener('input', handleInput);

                // Override the click event on the file input to capture when the dialog is opened
                fileInput.addEventListener('click', () => {
                    isFileDialogOpened = true;
                });

                // Simulate click to open file dialog
                fileInput.click();

                // Check if the dialog is opened after synchronous click call
                if (!isFileDialogOpened) {
                    // User canceled the file selection dialog
                    console.log("User canceled the file selection dialog without selecting a file.");
                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                }
            }
        }
    };

    const handleFileUpload1 = async () => {
        AppStateStore.showModalSpinner();
        try {
            if (!file1) {
                console.error('No file selected');
                return;
            }

            else if(file1.size > 2048000 ){
                setError1('File size exceeds the limit of 2MB.');
                AppStateStore.dismissModalSpinner();
                return;
            }

            const formData = new FormData();
            formData.append('file', file1);
            formData.append('image', '1');
            formData.append('id', chapter.id.toString());
            // const response = await axios.post(route(ApiRoutes.chapters.updateChapterCoverPhoto, {'id':chapter.id,'file':file}), {
            // });
            return ApiClient.
                // getInstance().post(route(ApiRoutes.chapters.updateChapterCoverPhoto, { id: chapter.id, file: file}))
                getInstance().post(route(ApiRoutes.chapters.updateChapterMainPhoto, { id: chapter.id }), formData)
                .then(
                    response => {
                        if (response.status >= 200 && response.status < 300) {
                            AppStateStore.dismissModalSpinner();
                            toast.success("Chapter Cover Photo Updated");
                            setFile1(null);
                            fetchData();
                        }
                        else {
                            fetchData();
                            AppStateStore.dismissModalSpinner();
                            toast.error("Failed to update Cover photo");
                        }
                    },
                    error => {
                        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
                            AppStateStore.showAlertModal("Error", message, m => {
                                m.hide();
                            });
                            AppStateStore.dismissModalSpinner();
                            return true;
                        });
                    }
                )
        } catch (error) {
        }
    };

    const handleImageClick1 = async (e: React.MouseEvent<HTMLDivElement>) => {
        setError1('');
        // Check if the click event originated from the designated area
        const isClickInDesignatedArea = e.target === e.currentTarget;
        if (isClickInDesignatedArea) {
            const fileInput = fileInputRef1.current;
            if (fileInput) {
                // Keep track of whether the file input dialog is opened
                let isFileDialogOpened = false;
                const currentFile = file1;

                // Define the input event handler
                const handleInput = () => {
                    if (!fileInput.files || fileInput.files.length === 0) {
                        // Restore the previous file if the selection was canceled
                        setCoverexists1(true);
                        setFile1(currentFile);

                        // User canceled the file selection dialog without selecting a file
                        console.log("User canceled the file selection dialog without selecting a file.");
                    } else {
                        // User selected file(s)
                        setCoverexists1(false);
                        console.log("User selected file(s):", fileInput.files);
                    }

                    // Reset the flag for the file dialog state
                    isFileDialogOpened = false;

                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                };

                // Add event listener for the input event
                fileInput.addEventListener('input', handleInput);

                // Override the click event on the file input to capture when the dialog is opened
                fileInput.addEventListener('click', () => {
                    isFileDialogOpened = true;
                });

                // Simulate click to open file dialog
                fileInput.click();

                // Check if the dialog is opened after synchronous click call
                if (!isFileDialogOpened) {
                    // User canceled the file selection dialog
                    console.log("User canceled the file selection dialog without selecting a file.");
                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                }
            }
        }
    };

    const handleFileUpload2 = async () => {
        AppStateStore.showModalSpinner();
        try {
            if (!file2) {
                console.error('No file selected');
                return;
            }

            else if(file2.size > 2048000 ){
                setError2('File size exceeds the limit of 2MB.');
                AppStateStore.dismissModalSpinner();
                return;
            }

            const formData = new FormData();
            formData.append('file', file2);
            formData.append('image', '2');
            formData.append('id', chapter.id.toString());
            // const response = await axios.post(route(ApiRoutes.chapters.updateChapterCoverPhoto, {'id':chapter.id,'file':file}), {
            // });
            return ApiClient.
                // getInstance().post(route(ApiRoutes.chapters.updateChapterCoverPhoto, { id: chapter.id, file: file}))
                getInstance().post(route(ApiRoutes.chapters.updateChapterMainPhoto, { id: chapter.id }), formData)
                .then(
                    response => {
                        if (response.status >= 200 && response.status < 300) {
                            AppStateStore.dismissModalSpinner();
                            toast.success("Chapter Cover Photo Updated");
                            setFile2(null);
                            fetchData();
                        }
                        else {
                            fetchData();
                            AppStateStore.dismissModalSpinner();
                            toast.error("Failed to update Cover photo");
                        }
                    },
                    error => {
                        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
                            AppStateStore.showAlertModal("Error", message, m => {
                                m.hide();
                            });
                            AppStateStore.dismissModalSpinner();
                            return true;
                        });
                    }
                )
        } catch (error) {
        }
    };

    const handleImageClick2 = async (e: React.MouseEvent<HTMLDivElement>) => {
        setError2('');
        // Check if the click event originated from the designated area
        const isClickInDesignatedArea = e.target === e.currentTarget;
        if (isClickInDesignatedArea) {
            const fileInput = fileInputRef2.current;
            if (fileInput) {
                // Keep track of whether the file input dialog is opened
                let isFileDialogOpened = false;
                const currentFile = file2;

                // Define the input event handler
                const handleInput = () => {
                    if (!fileInput.files || fileInput.files.length === 0) {
                        // Restore the previous file if the selection was canceled
                        setCoverexists2(true);
                        setFile2(currentFile);

                        // User canceled the file selection dialog without selecting a file
                        console.log("User canceled the file selection dialog without selecting a file.");
                    } else {
                        // User selected file(s)
                        setCoverexists2(false);
                        console.log("User selected file(s):", fileInput.files);
                    }

                    // Reset the flag for the file dialog state
                    isFileDialogOpened = false;

                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                };

                // Add event listener for the input event
                fileInput.addEventListener('input', handleInput);

                // Override the click event on the file input to capture when the dialog is opened
                fileInput.addEventListener('click', () => {
                    isFileDialogOpened = true;
                });

                // Simulate click to open file dialog
                fileInput.click();

                // Check if the dialog is opened after synchronous click call
                if (!isFileDialogOpened) {
                    // User canceled the file selection dialog
                    console.log("User canceled the file selection dialog without selecting a file.");
                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                }
            }
        }
    };


    const handleFileUpload3 = async () => {
        AppStateStore.showModalSpinner();
        try {
            if (!file3) {
                console.error('No file selected');
                return;
            }

            else if(file3.size > 2048000 ){
                setError3('File size exceeds the limit of 2MB.');
                AppStateStore.dismissModalSpinner();
                return;
            }

            const formData = new FormData();
            formData.append('file', file3);
            formData.append('image', '3');
            formData.append('id', chapter.id.toString());
            return ApiClient.
                getInstance().post(route(ApiRoutes.chapters.updateChapterMainPhoto, { id: chapter.id }), formData)
                .then(
                    response => {
                        if (response.status >= 200 && response.status < 300) {
                            AppStateStore.dismissModalSpinner();
                            toast.success("Chapter Cover Photo Updated");
                            setFile3(null);
                            fetchData();
                        }
                        else {
                            fetchData();
                            AppStateStore.dismissModalSpinner();
                            toast.error("Failed to update Cover photo");
                        }
                    },
                    error => {
                        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
                            AppStateStore.showAlertModal("Error", message, m => {
                                m.hide();
                            });
                            AppStateStore.dismissModalSpinner();
                            return true;
                        });
                    }
                )
        } catch (error) {
        }
    };

    const handleImageClick3 = async (e: React.MouseEvent<HTMLDivElement>) => {
        setError3('');
        // Check if the click event originated from the designated area
        const isClickInDesignatedArea = e.target === e.currentTarget;
        if (isClickInDesignatedArea) {
            const fileInput = fileInputRef3.current;
            if (fileInput) {
                // Keep track of whether the file input dialog is opened
                let isFileDialogOpened = false;
                const currentFile = file1;

                // Define the input event handler
                const handleInput = () => {
                    if (!fileInput.files || fileInput.files.length === 0) {
                        // Restore the previous file if the selection was canceled
                        setCoverexists3(true);
                        setFile3(currentFile);

                        // User canceled the file selection dialog without selecting a file
                        console.log("User canceled the file selection dialog without selecting a file.");
                    } else {
                        // User selected file(s)
                        setCoverexists3(false);
                        console.log("User selected file(s):", fileInput.files);
                    }

                    // Reset the flag for the file dialog state
                    isFileDialogOpened = false;

                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                };

                // Add event listener for the input event
                fileInput.addEventListener('input', handleInput);

                // Override the click event on the file input to capture when the dialog is opened
                fileInput.addEventListener('click', () => {
                    isFileDialogOpened = true;
                });

                // Simulate click to open file dialog
                fileInput.click();

                // Check if the dialog is opened after synchronous click call
                if (!isFileDialogOpened) {
                    // User canceled the file selection dialog
                    console.log("User canceled the file selection dialog without selecting a file.");
                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                }
            }
        }
    };

    const handleFileUpload4 = async () => {
        AppStateStore.showModalSpinner();
        try {
            if (!file4) {
                console.error('No file selected');
                return;
            }
            else if(file4.size > 2048000 ){
                setError4('File size exceeds the limit of 2MB.');
                AppStateStore.dismissModalSpinner();
                return;
            }

            const formData = new FormData();
            formData.append('file', file4);
            formData.append('image', '4');
            formData.append('id', chapter.id.toString());

            return ApiClient.
                getInstance().post(route(ApiRoutes.chapters.updateChapterMainPhoto, { id: chapter.id }), formData)
                .then(
                    response => {
                        if (response.status >= 200 && response.status < 300) {
                            AppStateStore.dismissModalSpinner();
                            toast.success("Chapter Cover Photo Updated");
                            setFile4(null);
                            fetchData();
                        }
                        else {
                            fetchData();
                            AppStateStore.dismissModalSpinner();
                            toast.error("Failed to update Cover photo");
                        }
                    },
                    error => {
                        Util.handleErrorResponse(error.response, null, undefined, (response, message) => {
                            AppStateStore.showAlertModal("Error", message, m => {
                                m.hide();
                            });
                            AppStateStore.dismissModalSpinner();
                            return true;
                        });
                    }
                )
        } catch (error) {
        }
    };

    const handleImageClick4 = async (e: React.MouseEvent<HTMLDivElement>) => {
        setError4('');
        // Check if the click event originated from the designated area
        const isClickInDesignatedArea = e.target === e.currentTarget;
        if (isClickInDesignatedArea) {
            const fileInput = fileInputRef4.current;
            if (fileInput) {
                // Keep track of whether the file input dialog is opened
                let isFileDialogOpened = false;
                const currentFile = file4;

                // Define the input event handler
                const handleInput = () => {
                    if (!fileInput.files || fileInput.files.length === 0) {
                        // Restore the previous file if the selection was canceled
                        setCoverexists4(true);
                        setFile4(currentFile);

                        // User canceled the file selection dialog without selecting a file
                        console.log("User canceled the file selection dialog without selecting a file.");
                    } else {
                        // User selected file(s)
                        setCoverexists4(false);
                        console.log("User selected file(s):", fileInput.files);
                    }

                    // Reset the flag for the file dialog state
                    isFileDialogOpened = false;

                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                };

                // Add event listener for the input event
                fileInput.addEventListener('input', handleInput);

                // Override the click event on the file input to capture when the dialog is opened
                fileInput.addEventListener('click', () => {
                    isFileDialogOpened = true;
                });

                // Simulate click to open file dialog
                fileInput.click();

                // Check if the dialog is opened after synchronous click call
                if (!isFileDialogOpened) {
                    // User canceled the file selection dialog
                    console.log("User canceled the file selection dialog without selecting a file.");
                    // Remove the event listener for the input event
                    fileInput.removeEventListener('input', handleInput);
                }
            }
        }
    };



    //   render(): React.ReactNode {
    return (
        <div>
            <div style={{display: 'grid', textAlign:'right', padding:20}}>
                *Photos uploaded on this page give LeTip permission to sync the images to the chapter's page on letip.com
            </div>
            <div style={{ display: 'flex' }}>
                <div style={{ paddingRight: 30 }}>
                    {coverexists ? (
                        <img
                            src={imagePath}
                            style={{ cursor: 'pointer', width: '250px', height: '200px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                            onClick={handleImageClick}
                        />
                    ) : file ? (
                        (
                            <img
                                src={URL.createObjectURL(file)}
                                alt="Uploaded"
                                style={{ cursor: 'pointer', width: '250px', height: '200px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                                onClick={handleImageClick}
                            />
                        )
                    ) : (
                        <div
                            style={{
                                width: '250px',
                                height: '180px',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={handleImageClick}
                        >
                            Click to upload
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                        ref={fileInputRef}
                    />
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                    {file && (
                        <button onClick={handleFileUpload} style={{ marginTop: '10px' }}>
                            Upload File
                        </button>
                    )}
                    <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', fontSize: '12px', fontWeight: 'bold', color: '#333', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', padding: '10px', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '5px' }}>Chapter Cover Image</div>
                </div>
                <div style={{ paddingRight: 15 }}>
                    {coverexists1 ? (
                        <img
                            src={imagePath1}
                            style={{ cursor: 'pointer', width: '200px', height: '150px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                            onClick={handleImageClick1}
                        />
                    ) : file1 ? (
                        (
                            <img
                                src={URL.createObjectURL(file1)}
                                alt="Uploaded"
                                style={{ cursor: 'pointer', width: '200px', height: '150px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                                onClick={handleImageClick1}
                            />
                        )
                    ) : (
                        <div
                            style={{
                                width: '180px',
                                height: '150px',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={handleImageClick1}
                        >
                            Click to upload
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange1}
                        ref={fileInputRef1}
                    />
                    {error1 && <div style={{ color: 'red' }}>{error1}</div>}
                    {file1 && (
                        <button onClick={handleFileUpload1} style={{ marginTop: '10px' }}>
                            Upload File
                        </button>
                    )}
                    <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', fontSize: '10px', fontWeight: 'bold', color: '#333', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', padding: '10px', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '5px' }}>Image 1</div>
                </div>
                <div style={{ paddingRight: 15 }}>
                    {coverexists2 ? (
                        <img
                            src={imagePath2}
                            style={{ cursor: 'pointer', width: '200px', height: '150px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                            onClick={handleImageClick2}
                        />
                    ) : file2 ? (
                        (
                            <img
                                src={URL.createObjectURL(file2)}
                                alt="Uploaded"
                                style={{ cursor: 'pointer', width: '200px', height: '150px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                                onClick={handleImageClick2}
                            />
                        )
                    ) : (
                        <div
                            style={{
                                width: '180px',
                                height: '150px',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={handleImageClick2}
                        >
                            Click to upload
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange2}
                        ref={fileInputRef2}
                    />
                    {error2 && <div style={{ color: 'red' }}>{error2}</div>}
                    {file2 && (
                        <button onClick={handleFileUpload2} style={{ marginTop: '10px' }}>
                            Upload File
                        </button>
                    )}
                    <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', fontSize: '10px', fontWeight: 'bold', color: '#333', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', padding: '10px', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '5px' }}>Image 2</div>
                </div>
                <div style={{ paddingRight: 15 }}>
                    {coverexists3 ? (
                        <img
                            src={imagePath3}
                            style={{ cursor: 'pointer', width: '200px', height: '150px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                            onClick={handleImageClick3}
                            alt="cover"
                        />
                    ) : file3 ? (
                        (
                            <img
                                src={URL.createObjectURL(file3)}
                                alt="Uploaded"
                                style={{ cursor: 'pointer', width: '200px', height: '150px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                                onClick={handleImageClick3}
                            />
                        )
                    ) : (
                        <div
                            style={{
                                width: '180px',
                                height: '150px',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={handleImageClick3}
                        >
                            Click to upload
                        </div>
                    )
                    }
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange3}
                        ref={fileInputRef3}
                    />
                    {error3 && <div style={{ color: 'red' }}>{error3}</div>}
                    {file3 && (
                        <button onClick={handleFileUpload3} style={{ marginTop: '10px' }}>
                            Upload File
                        </button>
                    )}
                    <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', fontSize: '10px', fontWeight: 'bold', color: '#333', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', padding: '10px', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '5px' }}>Image 3</div>
                    <div></div>
                </div>
                <div style={{ paddingRight: 15 }}>
                    {coverexists4 ? (
                        <img
                            src={imagePath4}
                            style={{ cursor: 'pointer', width: '200px', height: '150px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                            onClick={handleImageClick4}
                        />
                    ) : file4 ? (
                        (
                            <img
                                src={URL.createObjectURL(file4)}
                                alt="Uploaded"
                                style={{ cursor: 'pointer', width: '200px', height: '150px', border: '1px solid #555', boxShadow: '2px 2px 4px rgba(0, 0, 0, 0.4)' }}
                                onClick={handleImageClick4}
                            />
                        )
                    ) : (
                        <div
                            style={{
                                width: '180px',
                                height: '150px',
                                border: '2px dashed #ccc',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={handleImageClick4}
                        >
                            Click to upload
                        </div>
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        id="fileInput"
                        style={{ display: 'none' }}
                        onChange={handleFileChange4}
                        ref={fileInputRef4}
                    />
                    {error4 && <div style={{ color: 'red' }}>{error4}</div>}
                    {file4 && (
                        <button onClick={handleFileUpload4} style={{ marginTop: '10px' }}>
                            Upload File
                        </button>
                    )}
                    <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', fontSize: '10px', fontWeight: 'bold', color: '#333', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.3)', padding: '10px', background: 'rgba(255, 255, 255, 0.8)', borderRadius: '5px' }}>Image 4</div>
                </div>
            </div>
        </div>



    )
}

export default ChapterPhotosView;